import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';


// Import custom components
// import MainLayout from '../components/common/layout/MainLayout';
import NotFound from '../components/notfound';

// Views
import Home      from '../views/home';
import Search    from '../views/search';
import Admin     from '../views/admin';
import Auth      from '../views/auth';
import Roles     from '../views/roles';
import MyProfile from '../views/my-profile';


import PrivateRoute   from './PrivateRoute';
import { Main, None } from '../components/toplayout';

import withPermission from '../utils/with-permissions';
import { PERMISSION } from '../utils/constants';


const HomePrivateRoute    = withPermission([PERMISSION.HOME.VIEW])(PrivateRoute, true);
const SearchPrivateRoute  = withPermission([PERMISSION.SEARCH.VIEW])(PrivateRoute, true);
const AdminPrivateRoute   = withPermission([
  PERMISSION.ADMIN.VIEW,
  PERMISSION.ADMIN.CREATE,
  PERMISSION.ADMIN.UPDATE,
  PERMISSION.ADMIN.DELETE
])(PrivateRoute, true);
const RolesPrivateRoute = withPermission([
  PERMISSION.ROLES.VIEW,
  PERMISSION.ROLES.ASSIGN,
  PERMISSION.ROLES.CREATE,
  PERMISSION.ROLES.DELETE,
  PERMISSION.ROLES.REMOVE,
  PERMISSION.ROLES.UPDATE,
])(PrivateRoute, true);




const Routes = (props) => {
  const history = useHistory();
  let { isSignedIn, authenticated, user } = props;
  console.log('Routes props: ', props);

  console.log('authenticated', authenticated);
  console.log('isSignedIn', isSignedIn);
  console.log('Routes props: ', props );
  
  const reload = () => window.location.reload();

  return (
    <Switch>
      {
        isSignedIn ?

        <Switch>
          <Route exact path="/" render={(props) => (<Redirect to={{ pathname: '/home', state: { from: props.location } }} />)} />

          <HomePrivateRoute   layout={Main} path="/home"    component={Home}      isSignedIn={isSignedIn}/>
          <SearchPrivateRoute layout={Main} path="/search"  component={Search}    isSignedIn={isSignedIn}/>
          <AdminPrivateRoute  layout={Main} path="/admin"   component={Admin}     isSignedIn={isSignedIn}/>
          <RolesPrivateRoute  layout={Main} path="/roles"   component={Roles}     isSignedIn={isSignedIn}/>
          <PrivateRoute       layout={Main} path="/profile" component={MyProfile} isSignedIn={isSignedIn}/>

          <Route component={NotFound} />
        </Switch>
        :
          <Route layout={None} component={Auth}/>
      }
    </Switch>
  )
};

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('routers/index.js:mapStateToProps(state): ', state);
  return {
    user          : state.User,
    isSignedIn    : state.User.isSignedIn,
    email         : state.User.email,
  };
};

export default connect(mapStateToProps, {})(
  Routes
);
