import React      from 'react';
import { Button } from '@material-ui/core/'

import useStyles from './style';

const SecondaryButton = (props) => {

  let { children, onClick, className = [], ...rest } = props;

  const classes = useStyles();

  return (
    <Button
        color     = 'secondary'
        size      = 'medium'
        className = { [classes.button, className] }
        onClick   = { onClick }
        {...rest}>
      {children}
    </Button>
  )
};
export default SecondaryButton;