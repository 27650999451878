import { makeStyles } from '@material-ui/styles';
import colors from '../../utils/colors'

const drawerWidth = 232;
 
const useStyles = makeStyles(theme => ({
  '@global': {
    'body.swal2-toast-shown .swal2-container': {
      zIndex: theme.zIndex.drawer + 2
    }
  },

  appBar: {
    backgroundImage : `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    boxShadow       : 'none',
    position        : 'fixed',
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,    
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerOpen: {
    width: drawerWidth,
    overflow: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'visible',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

  menuTile: {
    backgroundColor: colors.SIDENAV_ORANGE,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  closeMenu: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: -9,
    top: 100,
    color: colors.SIDENAV_ORANGE,
    backgroundColor: 'rgba(244, 127, 52, 0.19)',
    borderRadius: '10%',
    height: 18,
    width: 18,
    cursor: 'pointer',
    zIndex: theme.zIndex.drawer + 1, 
  },
 
  listItem: {
    height: 75,
    paddingLeft: 25,
    color: colors.TEXT_BLACK,
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'default'
    },
    zIndex: '1!important',
  },
 
  selectedItem: {
    backgroundColor:  'rgba(244, 127, 52, 0.14)!important',
  },

  listIcon: {
    color : colors.SIDENAV_ICON,
    fontSize : 30, 
    '&:hover': {
      color: colors.SIDENAV_ORANGE,
      cursor: 'pointer'
    }
  },

  listText: {
    color : colors.SIDENAV_TEXT,
    fontSize : 16,
    fontWeight : '550',
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  toolbar: {
    flexWrap  : 'wrap',
    position  : 'relative',
    // margin    : theme.spacing(1, 3, 1, 3),
    '& h3'    : {
      position: 'absolute',
      left    : '44%',
      // flex: 1,
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      }
    }
  },

  logo: {
    height: 30,
    [theme.breakpoints.down("xs")]: {
      height: 20
    },
  },

  toolbarTitle: {
    display: 'flex',
    flexGrow: 1,
  },

  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // flex: 1,
    paddingLeft: '20px',
    justifyContent: 'flex-end',

    '& > .MuiTypography-root': {
      alignSelf: 'center',
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down("xs")]: {
      '& h6': {
        display: 'none'
      }
    }
  },

  button: {
    marginRight: theme.spacing(1),
  },

  subTabContainer: {
    '& > .MuiButton-root': {
      textTransform: 'none',
      borderRadius: 20,
      fontSize: 13,
      height: 'max-content'
    },

    '& > .MuiButton-root > .MuiButton-label': {
      color : colors.PRIMARY
    } 
  },

  subSettingIcon: {
    color: colors.PRIMARY,
    justifyContent: 'flex-end'
  },

  settingsContainer: {
    width: 'auto',
  },

  gradientCard: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 0, 0, 0),
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
  },

  noHover: {
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.TRANSPARENT,
      cursor: 'default'
    }
  },

  customMenuItem: {
    padding: theme.spacing(1, 4, 1, 4),

    '& .MuiListItemText-secondary': {
      color: colors.WHITE,
      fontSize: 12,
      whiteSpace: 'normal'
    },
  },

  menuItemLogout: {
    color : colors.SIDENAV_ORANGE,
    marginRight : 15,
    marginLeft : 15 ,
    justifyContent: 'center',
    fontSize : 16,
    fontWeight : '400',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  menuItemProfile: {
    color: colors.BLACK,
    marginRight : 15,
    marginLeft : 15,
    justifyContent: 'center',
    fontSize : 16,
    fontWeight : '400',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  

}));
 
export default useStyles;