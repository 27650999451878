import axios  from 'axios';
import api    from './api';
import { store } from '../index'

import { 
  CSRF_TOKEN,
  XML_HTTP_REQUEST 
} from '../utils/constants';

const axiosInstance = axios.create({
  baseURL : window.REACT_APP_SOS_API,
  headers : {
    'X-Requested-With': XML_HTTP_REQUEST,
    'Csrf-token'      : CSRF_TOKEN
  }
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = store.getState().User;

    if (user.token) config.headers.Authorization = api.BEARER+ user?.token?.jwt;

    //TODO: do something if token not existing
    return config;
  },
  (error) => {
    console.log('axiosInstance.interceptors.request.use error : ', error);
    return Promise.reject (error);
  }
);

export default axiosInstance;