const api = {

  TIMEOUT_LIMIT               : 1000,
  BEARER                      : 'Bearer ',

  GET_ALL_USER                : 'admin/user/allusers',
  GET_TOTAL_USER              : 'admin/user/totalusers',
  GET_USER_LOCATION           : 'admin/user/userslocation/',

  GET_ALL_ADMIN_USER          : 'admin/userAdmin/adminusers',
  GET_ALL_ROLES               : 'admin/userAdmin/allrole',
  GET_ALL_PERMISSIONS         : 'admin/userAdmin/permissions',
  GET_ROLE                    : 'admin/userAdmin/role',
  GET_ROLE_ADMINS             : 'admin/userAdmin/roleadmin',
  
  CREATE_ROLE                 : 'admin/userAdmin/createrole',
  UPDATE_ROLE                 : 'admin/userAdmin/updaterole',
  
  CREATE_NEW_ADMIN            : 'admin/userAdmin/addadmin',
  DELETE_ROLE                 : 'admin/userAdmin/deleterole',
  UPDATE_PERSONAL_INFORMATION : 'admin/userAdmin/updatepersonalinformation',
  CHANGE_EMAIL                : 'admin/userAdmin/changeemail',
  CHANGE_PASSWORD             : 'admin/userAdmin/changepassword',
  GET_USER_ADMIN_PERMISSION   : 'admin/userAdmin/admindetails',

  ADMIN_SIGNIN                : 'public/admin/signin',
  
};

export default api;
