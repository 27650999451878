import React, { useState, useEffect }from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Divider,
  Typography
}                                    from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Select                        from 'react-select';
import makeAnimated                  from 'react-select/animated';

import CModal                        from '../../../components/c-modal';
import LoadingButton                 from '../../../components/buttons/loading-button';
import { useStyles, selectStyle }      from './style';
import SecondaryButton               from "../../../components/buttons/secondary-button";

const NewAdminModal = (props) => {
  const classes     = useStyles();
  let { 
    isVisible, 
    onClose, 
    allRoles, 
    onSave, 
    handleChange, 
    handleRole, 
    isEmailExisting, 
    isPasswordNotMatch,
    isLoading,
    isDisabled
  } = props;

  const [ isPasswordVisible, setIsPasswordVisible ]     = useState(false);
  const [ confirmPassword, setConfirmPassword ]         = useState(false);
  const [ selectedOptions, setSelectedOptions ]         = useState();

  const animatedComponents                              = makeAnimated();

  const newRoles = allRoles?.map((role) => {  
    return { roleId: role.roleId, value: role.description ,label: role.title };
  });

  const selectedHandleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  }

  ////////////////////////////
  // useEffect()
  ////////////////////////////
  useEffect(() => {
    handleRole(selectedOptions)
  },[selectedOptions]);

  return ( 
    <CModal
      title={'CREATE NEW ADMIN'}
      isVisible = { isVisible } 
      onClose   = { onClose }>
        <form onSubmit={onSave}>
          <Box className = { classes.containerCModal }>
          <Box className = { classes.titleHeadingsModal }>PERSONAL INFORMATION</Box>

            <Box className = { classes.textInputBox }> 
              <Box className={classes.personalInfo}>
                <Typography className={classes.inputLabel}>First Name</Typography>
                <Tooltip title ='Type your first name correctly'> 
                <TextField
                required
                name        = 'firstName'
                className   = { classes.textInput }
                size        = 'small'
                id          = 'outlined-basic'
                variant     = 'outlined'
                onChange    = {(event) => {
                handleChange(event)
                }}/>
                </Tooltip>
              </Box>
              <Box className={classes.personalInfo}>
                <Typography className={classes.inputLabel}>Last Name</Typography>
                <Tooltip title = 'Type your last name correctly'>
                <TextField
                required
                name        = 'LastName'
                className   = { classes.textInput }
                size        = 'small'
                variant     = 'outlined'
                onChange    = { (event) => { handleChange(event) } } />
                </Tooltip>
              </Box>
            </Box>

            <Box className = { classes.titleHeadingsModal }>ACCOUNT</Box>

            <Box className = { classes.personalInfo }>
              <Typography className={classes.inputLabel}>Email Address</Typography>
              <Tooltip title = 'Type your email here'>
              <TextField
              required
              type        = {'email'}
              error       = { isEmailExisting }
              helperText  = { isEmailExisting ? "Email Already Exists." : "" }
              name        = 'email'
              className   = { classes.emailInput }
              size        = 'small'
              id          = 'standard-basic'
              variant     ='outlined'
              onChange    = {(event) => { handleChange(event) }} />
              </Tooltip>
            </Box>

            <Box className = { classes.textInputBox }> 
              <Box className={classes.personalInfo}>
                <Typography className={classes.inputLabel}>Password</Typography>
                <Tooltip title ='Secure your password'> 
                <TextField
                required
                error       = { isPasswordNotMatch }
                helperText  = { isPasswordNotMatch ? "Password does not match." : "" }
                name        =  'password'
                type        = { isPasswordVisible ? 'text' : 'password' }
                className   = { classes.textInput }
                size        = 'small'
                id          = 'outlined-basic'
                variant     = 'outlined'
                onChange    = { (event) => { handleChange(event) } }
                InputProps  = {{
                                endAdornment  : (
                                  <div className = { classes.visibleIcon }>
                                    <InputAdornment position='end'>
                                    <IconButton
                                    aria-label  = 'toggle password visibility'
                                    onClick     = { ()=> setIsPasswordVisible(prev => !prev) }
                                    onMouseDown = { ()=> setIsPasswordVisible(prev => !prev) }
                                    >
                                    { isPasswordVisible ? <Visibility/> : <VisibilityOff/> }
                                    </IconButton>
                                    </InputAdornment>
                                  </div>)
                  }}/>
                </Tooltip>
              </Box>
              <Box className={classes.personalInfo}>
                <Typography className={classes.inputLabel}>Confirm Password</Typography>
                <Tooltip title = 'Secure your password'>
                <TextField
                required
                name        = 'confirmPassword'
                type        = { confirmPassword ? 'text' : 'password' }
                className   = { classes.textInput }
                size        = 'small'
                id          = 'outlined-size-small'
                variant     = 'outlined'
                onChange    = { (event) => { handleChange(event) } }
                InputProps  = {{  endAdornment : (
                                    <div className = { classes.visibleIcon }>
                                      <InputAdornment position='end'>
                                        <IconButton
                                        aria-label  = 'toggle password visibility'
                                        onClick     = { ()=> setConfirmPassword(prev => !prev) }
                                        onMouseDown = { ()=> setConfirmPassword(prev => !prev) } >
                                          { confirmPassword ? <Visibility/> : <VisibilityOff/> }
                                        </IconButton>
                                      </InputAdornment>
                                    </div>) 
                                }} />
                </Tooltip>
              </Box>
            </Box>

            <Box className = { classes.titleHeadingsModal }>ROLES</Box>

            <Box className = { classes.textInputBox }>

              <Box className={classes.personalInfo}>
                <Typography className={classes.inputLabel}>Role</Typography>
                <Select
                value             = { selectedOptions }      
                onChange          = { selectedHandleChange }
                options           = { newRoles }
                className         = { classes.multiSelectBox }
                styles            = {selectStyle}
                closeMenuOnSelect = { false }
                isMulti
                isSearchable
                components        = { animatedComponents }
                noOptionsMessage  = { () => 'No other options available' }
                placeholder       = 'Choose privilege/s'/>
              </Box> 

            </Box>

          </Box>
          <Divider  />
          <Box className  = { classes.btnContainer }>
            <SecondaryButton onClick  = { onClose } className= {classes.cancelBtn}>
            <Typography>Cancel</Typography>
            </SecondaryButton>
            <LoadingButton
            disabled  = { isDisabled || isPasswordNotMatch || isEmailExisting }
            type      = 'submit'
            isLoading = { isLoading }
            className = { classes.saveBtn }
            >
            Save
            </LoadingButton>
          </Box>
        </form>
    </CModal>
    

  );
};
export default NewAdminModal;

