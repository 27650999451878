import React, { useState, useEffect } from 'react';

import {
  TextField,
  Tooltip,
  Button,
  Box,
  InputAdornment,
  ListItemText
} from '@material-ui/core';

import AdminPanelSettingsRounded from '@material-ui/icons/AdminPanelSettingsRounded';

import CModal    from '../../../components/c-modal';
import useStyles from './style';

import LoadingButton   from '../../../components/buttons/loading-button';
import SecondaryButton from '../../../components/buttons/secondary-button';
import PrimaryButton   from "../../../components/buttons/primary-button";

const DeleteRoleModal = (props) => {
  const classes = useStyles();

  let { isVisible, onClose, onSave, selectedRole, affectedAdmins } = props;

  return (
    <CModal isVisible={isVisible} onClose={onClose} title='Delete Role' onSave={onSave}>
      <Box className={classes.parentContainerCModal}>
        <Box className={classes.bodyModal}>You're about to delete the '{selectedRole?.role.title}'
          role. {affectedAdmins.length > 0 ? "Here's the list of users that will be affected:" : "There are no users that will be affected if you remove this role."}</Box>
        <Box className={classes.scrollableBox}>
          {
            affectedAdmins?.map(admin => {
              return (
                <ListItemText className={classes.listItem} primary={`${admin.userName} - @${admin.email}`}/>
              );
            })
          }
        </Box>
        <Box className={classes.bodyModal}>Are you sure you want to delete this role?</Box>
      </Box>
      <Box className={classes.btnContainer}>
        <SecondaryButton onClick={onClose} className={classes.cancelBtn}>
          No
        </SecondaryButton>

        <PrimaryButton onClick={onSave}>
          Yes
        </PrimaryButton>
      </Box>
    </CModal>
  );
}

export default DeleteRoleModal;