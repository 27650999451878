import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  logo: {
    alignSelf : 'flex-end',
    height: 50,
    marginBottom : 32,
    marginRight : 5,
    marginTop: 15,
    width: 220,
  },

  title: {
    fontWeight: 'bold',
    width: '-webkit-fill-available'
  },

  description: {
    marginTop : 60
  },

  button: {
    alignSelf : 'flex-end', 
    borderRadius: 20,
    display: 'flex', 
    marginTop : 60,
    textTransform: 'none'
  },

  johnnyLee: {
    marginTop: 32,
    width: 'auto'
  },

}));

export default useStyles;