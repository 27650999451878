const action = {

  SEARCH_GET_REQUEST        : "SEARCH_GET_REQUEST",
  SEARCH_GET_SUCCESS        : "SEARCH_GET_SUCCESS",
  SEARCH_GET_ERROR          : "SEARCH_GET_ERROR",

  SEARCH_SET_SEARCH         : "SEARCH_SET_SEARCH",

  SEARCH_ADD_REQUEST        : "SEARCH_ADD_REQUEST",
  SEARCH_ADD_SUCCESS        : "SEARCH_ADD_SUCCESS",
  SEARCH_ADD_ERROR          : "SEARCH_ADD_ERROR",
  
  SEARCH_UPDATE_REQUEST     : "SEARCH_UPDATE_REQUEST",
  SEARCH_UPDATE_SUCCESS     : "SEARCH_UPDATE_SUCCESS",
  SEARCH_UPDATE_ERROR       : "SEARCH_UPDATE_ERROR",
  SEARCH_UPDATE_CRITERIA    : "SEARCH_UPDATE_CRITERIA",
  
  SEARCH_DELETE_REQUEST     : "SEARCH_DELETE_REQUEST",
  SEARCH_DELETE_SUCCESS     : "SEARCH_DELETE_SUCCESS",
  SEARCH_DELETE_ERROR       : "SEARCH_DELETE_ERROR",


  SEARCH_UPDATE_SEARCH_LIST : "SEARCH_UPDATE_SEARCH_LIST",

  getSearches: () => ({
    type    : action.SEARCH_GET_REQUEST
  }),

  setSearch: search => ({
    type    : action.SEARCH_SET_SEARCH,
    payload : search
  }),

  addSearch: search => ({
    type    : action.SEARCH_ADD_REQUEST,
    payload : search
  }),

  updateSearch: search => ({
    type    : action.SEARCH_UPDATE_REQUEST,
    payload : search
  }),

  updateSearchSuccess: search => ({
    type    : action.SEARCH_UPDATE_SUCCESS,
    payload : search
  }),

  updateSearchCriteria: searchCriteria => ({
    type    : action.SEARCH_UPDATE_CRITERIA,
    payload : searchCriteria
  }),

  deleteSearch: search => ({
    type    : action.SEARCH_DELETE_REQUEST,
    payload : search
  })

};

export default action;
