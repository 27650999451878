import React, { useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';

const Toast = (props) => {

	const { open, setOpen, message, severity } = props;
	
	const Alert = (props) => {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	const handleClose = (event, reason) => {
		
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Portal>
			<Snackbar
				anchorOrigin={{
												vertical		: 'top',
												horizontal	: 'right',
										}}
				open={open}
				autoHideDuration={3000}
				message={message}
				onClose={handleClose}
			>
				<Alert severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</Portal>
	)
}

export default Toast;