import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Box, TextField, CssBaseline, Button, Avatar, Container, CircularProgress, Paper, Typography } from '@material-ui/core';
import useStyles from './style';
import { userRequest } from '../../service/requests';
import { ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR } from '../../utils/constants';
import userAction from '../../redux/user/action';
import action from '../../redux/action';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';


const { signOutUser } = action;
const { authenticateUser, signInAdmin } = userAction;

const Auth = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { showAlert, signInAdmin } = props;

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [state, setState] = useState({
    email: '',
    isEmailError: false,
    emailErrorDesc: '',
    password: '',
    isPasswordError: false,
    passwordErrorDesc: '',
    isPasswordVisible: false
  });

  const {
    email, isEmailError, emailErrorDesc,
    password, isPasswordError, passwordErrorDesc
  } = state;

  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleInputChange = (event) => {
    let value = event.target.value;
    let emailError = {}

    if (event.target.name == 'email') {
      const isEmailInvalid = !emailPattern.test(value);
      emailError = {
        isEmailError: isEmailInvalid ? true : false,
        emailErrorDesc: isEmailInvalid ? 'Invalid email format.' : '',
      }
    }

    setState({
      ...state,
      ...emailError,
      [event.target.name]: value
    });
  };

  const authenticate = (e) => {
    e.preventDefault();


    setState({
      ...state,
      isEmailError     : email === '' || !emailPattern.test(email) ? true : false,
      emailErrorDesc   : (email === '' || !emailPattern.test(email)) ? 'Invalid email address' : '',
      passwordErrorDesc: password === '' ? 'Required.' : '',
    });

    if(email === '' || password === '' || !emailPattern.test(email)) {
      return
    }

    setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
    userRequest.signInAdmin({
      email   : email,
      password: password
    }).then(response => {
      console.log('userRequest.signInAdmin response : ', response);

      setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
      if(response.data.status.code == "200") {
        signInAdmin({ user: response?.data?.userAdmin, token: response?.data?.tokenAdmin, roles:response?.data?.roles });
        history.push('/home');
      } else {
        setState({
          ...state,
          isEmailError     : true,
          emailErrorDesc   : '',
          isPasswordError  : true,
          passwordErrorDesc: 'Invalid email or incorrect password.'
        });
      }
    }).catch(error => {
      console.log('error : ', error);

      setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
      showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
    });
  };


  const handleClickShowPassword = () => {
    setState({ ...state, isPasswordVisible: !state.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (

    <form className={classes.formParentContainer}> 
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={isEmailError}
              helperText={emailErrorDesc}
              onChange={handleInputChange}
              value={email}
              size="small"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={state.isPasswordVisible ? 'text' : 'password'}
              autoComplete="current-password"
              error={isPasswordError}
              helperText={passwordErrorDesc}
              onChange={handleInputChange}
              value={password}
              size="small"

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={authenticate}
              size="small"
            >
              {
                isLoggingIn ?
                  <CircularProgress color='white' size={24} />
                  :
                  'Authenticate'
              }
            </Button>
          </Box>
        </Paper>
      </Container>
    </form>
  
  );
}
/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('auth/index.js:mapStateToProps(state)');
  console.log(state);
  return {

  };
}
export default connect(mapStateToProps, { authenticateUser, signInAdmin, signOutUser })(Auth);