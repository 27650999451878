import React, { useEffect, useState, useCallback } from 'react';
import { connect }                    from 'react-redux';
import SearchIcon                     from '@material-ui/icons/Search';
import {
  Box,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography
} from '@material-ui/core';

import NO_USERS_FOUND                                           from '../../assets/images/no_users_found.png';
import useCommonClasses                                         from '../../components/common/style'
import Empty                                                    from '../../components/empty';
import { ADMIN_TABLE_COLUMN, FILTER_SPECIAL_CHARACTERS, HTTP_STATUS, SEARCH_TABLE_COLUMN } from '../../utils/constants';
import { getFormattedDateTime, handleHttpError }                from '../../utils/helper';

import { userRequest } from '../../service/requests';

import useStyles         from './style';
import { debounce }       from 'lodash';
import NextBeforeButtons from "../../components/table/next-before-buttons";
import { DataGrid }      from "@material-ui/data-grid";


const Search = (props) => {
  const classes       = useStyles();
  const commonClasses = useCommonClasses();

  const [ inputValue, setInputValue ] = useState('');
  const [ allUser, setAllUser ]       = useState([]);

  let [ page, setPagination ]       = useState(1);
  let [ keyword, setkeywordSearch ] = useState('');

  const [ totalPage, setTotalPage ] = useState(1);
  const [ debouncedCallApi, setdebouncedCallApi ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);

  console.log('Search()');

  const debouncedSearch = useCallback(debounce(async (keyword,page)=> {
    console.log("keyword", keyword);
    const response = await userRequest.getAllUsers({
      keyword : keyword,
      page    : page,
    }).catch(err=>{
      setIsLoading(false)
      handleHttpError(err, "userRequest.getAllUsers")
    });

    console.log("response", response);

    setTotalPage(response.data.userAllWithPage.pageCount.count);

    const user = response.data.userAllWithPage.userAll.map(user=>{
      let formattedSubscriptionPlanEnd = getFormattedDateTime(user?.subscriptionPlanEnd, 'unix', 'YYYY-MM-DD')
      return { ...user, subscriptionPlanEnd: formattedSubscriptionPlanEnd };
    });

    setAllUser(user);
    setIsLoading(false);
  }, 500));

  useEffect(()=>{
    debouncedSearch(keyword, page);
  },[]);

  useEffect(()=>{
    setIsLoading(true);
    debouncedSearch(keyword, page);
  }, [keyword,page]);
  

  /////////////////////////
  ///Get Search inputed
  ///////////////////////
  const handlerSearchInput = async(e) => {
    let searchValue = e.target.value;
    setInputValue(searchValue);
    setkeywordSearch(searchValue);
    setPagination(1);
  };

  /////////////////////////
  ///Reset Search Field
  ///////////////////////
  const resetSearchField = () => {
    setInputValue('');
    setPagination(1);
    setkeywordSearch('');
  };


  //////////////////////////
  ///Button Search Action
  ////////////////////////
  const onClickSearch = () => {
    console.log(inputValue);
    setkeywordSearch(inputValue);
    console.log(allUser);

  };
  //////////////////////////////
  ///Enter Key Search Action
  ////////////////////////////
  const handlerKeyEnter = (event) => {
    if(event.key === 'Enter') {
      console.log(inputValue);
      setInputValue('');
    }
  };

  const onSetPage = (page) => {
    setPagination(page)
  }

  const onPageNext = () => {
    if(page != totalPage){
      setPagination(page + 1);
    }
    console.log(page);
  };

  const onNextTenPages = () => {
    if( page <= totalPage - 10){
      setPagination(page + 10);
    }
    console.log(page);
  };

  const onPageBefore = () => {
    if(page != 1){
      setPagination(page - 1);
    }
    console.log(page);
  };

  const onPreviousTenPages = () => {
    if(page >= 10){
      setPagination(page - 10);
    }
    console.log(page);
  };

  const newAllUser = allUser?.map((item) => { 
  return { ...item, gender : item.gender == 'MAL' ? 'Male' : 'Female', 
  viewStatus: item.viewStatus == 'REG' ? 'Regular' : item.viewStatus == 'PRV' ? 'Privacy' : 'Elite' }
  });

  return (
    <div className={commonClasses.root}>
      <Box className={classes.box}>
        <div className={classes.label}>
          <Typography variant='h2' className={classes.labelText}>Users</Typography>
          <TextField
            placeholder='Search'
            // onKeyDown={handlerKeyEnter}
            variant = 'outlined'
            value={inputValue}
            size='small'
            onChange={handlerSearchInput}
            className={classes.searchField}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position='start' disablePointerEvents={true}>
                  <SearchIcon/>
                </InputAdornment>
              )
            }}
          />
        </div>                               
        {
          <div className={classes.table} style={{height: 'auto'}}>
            <DataGrid
              rows = { newAllUser }
              columns ={ SEARCH_TABLE_COLUMN }
              getRowId = { (row) => row.userId }
              disableColumnMenu
              disableSelectionOnClick
              pageSize = { 10 }
              autoHeight = { true }
              components={{
              Footer: () => <NextBeforeButtons
                page = { page }
                TotalPage = { totalPage }
                onPageBefore = { ()=> onPageBefore() }
                onPageNext = { ()=> onPageNext() }
                onNextTenPages = { ()=> onNextTenPages()}
                onPreviousTenPages = { ()=> onPreviousTenPages()}
                onSetPage = {onSetPage}
                isLoading = {isLoading}
                />,
                }}/>
            {
              <div className   = { classes.notFoundContainer }>
                <div className = { classes.notFound }>
                  { isLoading ?
                    <CircularProgress color='primary' /> :

                    (allUser?.length < 1) &&
                      <Empty
                        title        = { "No User" }
                        description  = { "Not Found" }
                        image        = { NO_USERS_FOUND }/>
                  }
                </div>
              </div>
            }
          </div>
        }
      </Box>
    </div>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('search/result/index.js:mapStateToProps(state)');
  console.log(state);
  return {
   
  };
}
export default connect(mapStateToProps)(
  Search
)