import React from 'react';
import { makeStyles } from '@material-ui/styles';

import TopBar from '../topbar';
import { Container } from '@material-ui/core';
import NavigationBar from '../topbar/navigation-bar/index.js';
import useCommonStyles from '../common/style';

const useStyles = makeStyles(theme => ({
  root: {
    height         : '100%',
    width          : '100%'
  },
  
  none: {
    height         : '100%',
    display        : 'flex',
    alignItems     : 'center',
    justifyContent : 'center'
  }
}));



export const Main = (props) => {
  const { children } = props;
  
  const classes = useStyles();
  
  return (
    <div>
      <TopBar  {...props} />
      <div style={{paddingTop: '80px'}}>
      {
        children
      }
      </div>
    </div>
  );
};

export const None = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.none} >
      {
        children
      }
    </div>
  );
};
