import action from "./action";

const initState = {
  user: {},
};

export default function userReducer(state = initState, act) {

  console.log('userReducer act: ', act);
  switch (act.type) {

    case action.ADMIN_SIGNIN:

      let roles = act.payload.roles;

      console.log('roles in reducer: ', roles);
      let permissions = [];
      if (roles) {
        let tempPermissions = [];
        roles.forEach(r => r?.permissions?.forEach(p => tempPermissions.push(p?.code)));
        permissions = [... new Set(tempPermissions)]
      }

      console.log("redux/user/reducer.js:ADMIN_SIGNIN");
      return {
        ...state,
        ...act.payload.user,
        token: act.payload.token,
        roles,
        permissions,
        isSignedIn: true
      };

    case action.USER_TOKEN_UPDATE:
      console.log("redux/user/reducer.js:USER_TOKEN_UPDATE");
      return {
        ...state,
        token: act.payload,
      };

    case action.UPDATE_PERSONAL_INFO:
      console.log("redux/user/reducer.js:PERSONAL_UPDATE");
      return {
        ...state,
         [act.payload.field]: act.payload.value
      };
    case action.SET_USER_ADMIN_PERMISSION: {
      console.log("redux/user/reducer.js:SET_USER_ADMIN_PERMISSION");
      return {
        ...state,
        permissions: act.payload
      }
    }

    default:
      return state
  }
}
