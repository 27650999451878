import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  paper: {

    padding           : theme.spacing(4),
    marginTop         : theme.spacing(8),
    display           : 'flex',
    flexDirection     : 'column',
    alignItems        : 'center',
    justifyContent    : 'center',
  },

  formParentContainer:{

    marginTop         : '12%'
  },

  avatar: {

    margin            : theme.spacing(1),
    backgroundColor   : theme.palette.secondary.main,
  },

  form: {

    width             : '100%', // Fix IE 11 issue.
    marginTop         : theme.spacing(1),
  },

  submit: {

    margin            : theme.spacing(3, 0, 2),
  },

  
}));
export default useStyles;