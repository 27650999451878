import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  root : {
    position          : 'absolute',
    backgroundColor   : colors.WHITE,
    display           : 'flex',
    flexDirection     : 'row',
    padding           : 20,
    borderRadius      : 10,
    minHeight         :'70%',
  },

  tabDiv : {
    position          :'relative',
    cursor            : 'pointer',
    width             : '95%',
    minHeight         : '50px',
    maxHeight         : '50px',
    alignItems        : 'center',
    display           : 'flex',
    flexDirection     : 'column',
    padding           : '10px 0px 10px 5px',
    borderLeftStyle   : 'solid',
    borderLeft        : '5px'
  },

  tabActive : {
    borderColor       : colors.PRIMARY
  },

  tabText : {
    textOverflow      : 'ellipsis',
    overflow          : 'hidden',
    whiteSpace        : 'nowrap',
    maxWidth          : '57%'
  },

  tabSpan : {
    display           : 'flex',
    flexDirection     : 'row',
    alignItems        : 'center',
    paddingLeft       : '15px',
    width             : '100%',
  },

  spanBox : {
    display           : 'flex',
    justifyContent    : 'flex-end',
    marginRight       : '10px'
  },

  spanBoxButton : {
    maxWidth          : '30px',
    maxHeight         : '30px',
    minWidth          : '30px',
    minHeight         : '30px'
  },

  subHeaderBox : {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    display           : 'grid',
    justifyContent    : 'space-around',
    width             : '96.6%',
    height            : '555px',
    padding           : 15,
    overflowY         : 'scroll',
  },

  tabButton: {
    backgroundColor   : colors.WHITE,
    letterSpacing     : 2,
    color             : colors.BLACK,
    textOverflow      : 'ellipsis',
    overflow          : 'hidden',
    whiteSpace        : 'nowrap',
    marginRight       : 15,
    '&:hover': {
      backgroundColor: colors.GRAY
    },
  },
  
  subroot:{
    backgroundColor   : colors.WHITE,
    display           : 'flex',
    flexDirection     : 'column',
    width             : '100%',
  },
  actionButtonBox:{
    display           : 'flex',
    justifyContent    : 'space-evenly',
    marginTop         : 10,
  },
  rolesBox:{
    width             : '100%',
    height            : '850px',
    overflow          : 'hidden',
    display           : 'flex',
    flexDirection     : 'column',
    marginLeft        : '30px',
    marginRight       : '30px'
    // marginRight       :10,
  },

  box: {
    width: '100%',
    height: '850px',
    display: 'flex',
    flex: 6,
    flexDirection: 'row'
  },

  box1: {
    flex: 1, 
    borderRight: `2px solid rgba(0, 0, 0, 0.12)`
  },

  addButton:{
    height            : '40px',
    width             : '145px',
  },

  removeButton:{
    color             : colors.RED,
    '&:hover': {
      backgroundColor: colors.TRANSPARENT
    },
  },
  editButton:{
    color             :colors.PRIMARY,
    marginRight : '10px',
    '&:hover': {
      backgroundColor: colors.TRANSPARENT
    },
  },
  Header:{

   color              : colors.WHITE,
   margin             : 10,
  },
  roleHeader:{

    color              : colors.BLACK,
    margin             : 10,
   },
  roleHeaderBox:{
    backgroundColor   : colors.WHITE,
    display           : 'flex',
    justifyContent    : 'flex-start',
    borderRadius      : 5,
  },
  HeaderBox:{
    backgroundColor   : colors.PRIMARY,
    display           : 'flex',
    justifyContent    : 'flex-start',
    borderRadius      : 5,
  },

  //New Design Styles
  firstBox: {
    display:'flex',
    flexDirection: 'column',
    flex: 1,
  },

  labelBox: {
    display:'flex',
    flex: 1.5,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `2px solid rgba(0, 0, 0, 0.12)`
  },

  labelText: {
    fontSize: '24px', 
    fontWeight: 'bold'
  },

  adornment: {
    backgroundColor: colors.SIDENAV_ORANGE,
    color: colors.WHITE,
    padding: '20px 14px',
    borderTopLeftRadius: theme.shape.borderRadius + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius + 'px',
    '&:hover':{
      cursor: 'pointer'
    }
  },

  searchField: {
    display: 'flex',
    paddingRight: '30px', 
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
    },
    '@media only screen and (max-width: 768px)': {
      width: '100%',
    }
  },

  roleListBox: {
    display:'flex',
    flex: 8.5,
    flexDirection: 'column'
  },

  roleLists: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '30px',
  },

  roleTitle: {
    fontSize: '16px',
    '&:hover':{
      cursor: 'pointer'
    }
  },

  selectedRole: {
    color: colors.PRIMARY,
    fontWeight: '900'
  },

  secondBox: {
    display:'flex',
    flexDirection: 'column',
    flex: 1,
    borderLeft: `2px solid rgba(0, 0, 0, 0.12)` 
  },

  accordionBox : {
    display:'flex',
    flexDirection: 'column',
    flex: 7,
    overflowX: 'hidden',
    borderBottom: `2px solid rgba(0, 0, 0, 0.12)`
  },

  accordion:{
    margin: '10px 20px 0px 20px',
    backgroundColor   : colors.WHITE,
    "&.MuiAccordion-root:before": {
      backgroundColor : "white"
    },
    "&.MuiAccordion-root.Mui-disabled": {
      backgroundColor : "white",
      color           : colors.DARK_GRAY
    },
  },

  accordionIcons: {
    padding: '0px 10px 10px 0px'
  },

  accordionDetails:{

    display           : 'flex',
    flexDirection     : 'column',
    marginLeft        : '60px'
  },

  permissionLabelBox : {
    display:'flex',
    alignItems: 'center',
    flex: 1.5,
    borderBottom: `2px solid rgba(0, 0, 0, 0.12)`,
    paddingLeft: '30px', 
  },

  thirdBox: {
    display:'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1.5, borderBottom: `2px solid rgba(0, 0, 0, 0.12)`
  },

  permissionListBox : {
    flex: 7,
    display:'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden',
    borderBottom: `2px solid rgba(0, 0, 0, 0.12)`,
    borderLeft: `2px solid rgba(0, 0, 0, 0.12)`,
  },

  chip:{
    margin            : '20px 15px 20px 0px',
    height            : '50px',
    width             : '145px',
    alignSelf         : 'center',
    '.MuiChip-label': {
      fontSize        : '12px'
    }
  },

  saveBtnBox: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1.5,
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor         : 'rgba(244, 127, 52, 0.4)',
      color                   : colors.WHITE
    }
  },

  saveBtn: {
    width: '115px',
    height: '50px',
    fontSize: '16px',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor         : colors.PRIMARY,
      color         : colors.WHITE
    }
  },

  cancelBtn: {
    color           : colors.TEXT_BLACK,
    fontSize        : '16px',
    cursor          : 'pointer',
  },

}));

export default useStyles;