import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  box:{
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  pages: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    marginLeft: '5px',
    marginRight: '5px'
  },

  separator: {
    backgroundColor: colors.BACKGROUND_COLOR,
    color: colors.GRAY,
    height: 25,
    width: 25,
    borderRadius: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .MuiTypography-root': {
      fontSize:'12px',
      fontWeight: '600'
    },
  },

  page: {
    color: colors.SIDENAV_ORANGE,
    backgroundColor: 'rgba(244, 127, 52, 0.19)',
    height: 25,
    width: 25,
    borderRadius: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    '& > .MuiTypography-root': {
      fontSize:'12px',
      fontWeight: '600'
    },
    marginLeft: '2px',
    marginRight: '2px'
  },

  pointer: {
    cursor: 'pointer',
  },
  
  currentPage:{
    backgroundColor: '#F47F34',
    color: colors.WHITE,
    height: 25,
    width: 25,
    borderRadius: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .MuiTypography-root': {
      fontSize:'12px',
      fontWeight: '600'
    },
  },

  iconBox: {
    display: 'flex',
    margin: '1px',
    color: colors.SIDENAV_ORANGE,
    backgroundColor: 'rgba(244, 127, 52, 0.19)',
    borderRadius: '10%',
    height: 25,
    width: 25,
  },

}));

export default useStyles;