import React, { Component, useEffect } from 'react';
import useStyles from './style';

import { userRequest } from '../../service/requests';

import GoogleMapReact from 'google-map-react';

class GoogleMap extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 5,
        heatmap: {
            options:{
                radius: 35,
                opacity: 1,
            }
        }
    }

    render() {
        return (
            <div onLoad={() => console.log(this?.props?.heatmapData)} style={{height: '60vh', borderRadius: 5, overflow: 'hidden'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ 
                        key: ''/* API KEY */,
                        libraries: ['visualization']}}
                    defaultCenter={this?.props?.centerInMap}
                    defaultZoom={this?.props?.zoomInMap}
                    heatmap={{positions:this?.props?.heatmapData, options: this?.props?.heatmap?.options}}>

                </GoogleMapReact>
            </div>
        );
    };
}

export default GoogleMap;