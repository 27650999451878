import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  // SEARCH
  root: {
    position   : 'absolute',
    marginTop  : '120px',
    marginLeft : 'auto',
    marginRight: 'auto',
    left       : 0,
    right      : 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: '165px'
    },

    '& > .MuiGrid-root': {
      marginTop: theme.spacing(4),
    },
  },

  flexEnd: {
    alignItems    : 'flex-end',
    display       : 'flex',
    justifyContent: 'flex-end',
  },

  header: {
    borderBottom  : `1px solid ${colors.TEXT_GRAY}`,
    display       : 'flex',
    flexDirection : 'row',
    justifyContent: 'space-between',
    padding       : theme.spacing(3, 0, 1, 0),
  },

  headerLeft: {
    flex      : 1,
    fontWeight: 'bold',
    padding   : theme.spacing(0, 0, 0, 4),
  },

  headerRight: {
    flex          : 1,
    display       : 'flex',
    fontWeight    : 'bold',
    padding       : theme.spacing(0, 4, 0, 0),
    justifyContent: 'flex-end'
  },

  paper: {
    backgroundColor: colors.BACKGROUND_COLOR,
    marginTop: theme.spacing(4),
  },

  searchContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },

  searchFieldContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
  },

  table:{
    height                    : 720,
    backgroundColor           : colors.WHITE,
    textAlign                 : 'center',
    overflow                  : 'hidden',
    "@media only screen and (max-height: 860px)": {
      height : 600,
    },
    "@media only screen and (max-height: 500px)": {
      height : 300,
    }
  },

  notFoundContainer: {
    display                   : 'flex',
    flex                      : 1,
    justifyContent            : 'center',
  },

  notFound: {
    position                  : 'absolute',
    width                     : 'auto',
    top                       : 300,
    backgroundColor           : colors.WHITE,
    textAlign                 : 'center'
  },

  defaultSearch: {
    fontWeight: 'bold',
  },

  searchCount: {
    display: 'inline-flex'
  },

  action: {
    flex: 1,
    minWidth: 80,
    display: 'flex',
    justifyContent: 'flex-end'
  },

  actionButtonEmpty: {
    height: 24,
    minHeight: 24,
    width: 24,
    minWidth: 24,
    margin: theme.spacing(0, 0, 0, 1),
  },

  actionButton: {
    backgroundColor: 'transparent',
    // borderRadius: 32/2,
    // height: 32,
    // minHeight: 32,
    // width: 32,
    // minWidth: 32,
    padding: 0,
    margin: theme.spacing(0, 0, 0, 1),
    zIndex: 2,

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
  },

  // MESSAGE CENTER CARD

  notifCard: {
    alignItems: 'center',
    backgroundColor: colors.WHITE,
    display: 'flex',
    padding: 5,
    position: 'relative'
  },

  image: {
    
    margin: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height: 50,
      width: 50,
      borderRadius: 50/2,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
      width: 75,
      borderRadius: 75/2,
    },
    [theme.breakpoints.up('md')]: {
      height: 75,
      width: 75,
      borderRadius: 75/2,
    },
  },

  contentView: {
    flex: '1',
    alignSelf: 'center',
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  

  // Search Result
  searchResult: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  
  labelText: {
    padding: '30px 20px 30px 20px', 
    display: 'flex', 
    alignSelf: 'center', 
    fontSize: '24px', 
    fontWeight: 'bold'
  },

  adornment: {
    backgroundColor: colors.SIDENAV_ORANGE,
    color: colors.WHITE,
    padding: '20px 14px',
    borderTopLeftRadius: theme.shape.borderRadius + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius + 'px',
    '&:hover':{
      cursor: 'pointer'
    }
  },

  searchField: {
    display: 'flex',
    padding: '30px 20px 20px 20px',
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
    },
    '@media only screen and (max-width: 768px)': {
      width: '100%',
    }
  },

  box: {
    margin: '10px 20px 20px 20px',
    width: '100%',
    height: 'auto',
    '& .header-style': {
      backgroundColor: '#F3F6F9',
    },
    '& .MuiDataGrid-root': {
      border: '0px',
    },
    '& .MuiDataGrid-root .MuiDataGrid-row': {
      color: 'black',
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      borderBottom: '12px solid white',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#5E6A7A'
    },
    '& .MuiDataGrid-iconSeparator': {
      color: 'black',
      visibility: 'hidden'
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within':{
      outline : 0
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within':{
      outline : 0
    }
  },

  SeachButtonbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  searchButton:{
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE
  },

  clearButton:{
    backgroundColor: colors.TRANSPARENT,
    color: colors.TEXT_GRAY
  },
  
  paginationButtonContainer:{
    display: 'flex',
    justifyContent: 'flex-end'
  }

}));

export default useStyles;