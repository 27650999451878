import { makeStyles } from '@material-ui/styles';
import colors from '../../../utils/colors'

const useStyles = makeStyles(theme => ({
  
  tabsContainer: {
    paddingTop        : 100,
    width             : 'fit-content',

    '& .MuiTab-wrapper':{
      flexDirection: 'row',
      justifyContent: 'space-around'
      },
    '& .MuiTabs-root:nth-child(1)': {
      flex: 0.9
    },
    '& .subTabs .MuiTabs-flexContainer': {
      paddingRight: 20
    },
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      '& .subTabs .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'center',
      },
    }
  },

  tab: {
    backgroundColor    : colors.GRAY,
    color              :colors.WHITE,
    borderRadius       : 5,
    textTransform      : 'capitalize',
    minWidth           : '100px',
    minHeight          :'10px',
    marginRight        : 20,
  },

  selectedTab: {
    backgroundColor    : colors.PRIMARY,
    color              :colors.WHITE,
    borderRadius       : 5,
    textTransform      : 'capitalize',
    minWidth           : '100px',
    minHeight          :'10px',
    marginRight        : 20,
  },

  


}));

export default useStyles;