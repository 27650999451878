import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  button: {
    width: '110px'
  }

}));

export default useStyles;