import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
	container: {
		textAlign: 'center'
	}
}))

export default useStyles;
