import { all, takeEvery }          from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import searchSaga       from './search/saga';
import userSaga         from './user/saga';
import action           from './action';

/////////////////////////
// reqUserSignOut()
/////////////////////////
function* reqUserSignOut() {

  try {
    console.log('reqUserSignOut()');

    sessionService.deleteSession();
    sessionService.deleteUser();
  } catch (error) {
    console.log('error : ', error);
  }
}

export default function* rootSaga() {
  yield all([
    searchSaga(),
    userSaga(),
    takeEvery(action.SIGN_OUT, reqUserSignOut)
  ]);
}
