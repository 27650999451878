import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

    root: {
        position: 'absolute',
        marginTop: '120px',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        [theme.breakpoints.down("sm")]: {
          marginTop: '165px'
        },
    
        '& > .MuiGrid-root': {
          marginTop: theme.spacing(4),
        },
    },


    btnContainer: {
        display                  : 'flex',
        backgroundColor          : 'transparent',
        justifyContent           : 'flex-end',
        paddingRight             : '25px',
        paddingBottom            : '20px',
        paddingTop               : '50px'
    },

    titleHeadingsModal:{
        backgroundColor          : 'transparent',
        color                    : '#F1793E',
        fontSize                 : '2em',
        fontWeight               : 'bold',
        textAlign                : 'left',
        marginLeft               : '10px',
        marginBottom             : '5px'
    },

    txtFieldContainer: {
        display                  : 'flex',
        gap                      : '7px',
        padding                  : '12px',
        marginLeft               : '10px',
        marginRight              : '2px',
        marginBottom             : '9px'
    },

    nameTxtField: {
        paddingTop               : '3px',
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '100%',
    },

    firstNameTxtField: {
        backgroundColor          : 'transparent',
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '50%',
    },

    lastNameTxtField: {
        backgroundColor          : 'transparent',
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '50%'
    },

    emailTxtField: {
        width                    : '47%',
        paddingRight             : '8px',
        marginLeft               : '10px'
    },

    passwordField: {
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '50%',

    },

    confirmPassField: {
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '50%'
    },

    multiSelectBox: {
        paddingTop               : '3px',
        paddingRight             : '8px',
        marginLeft               : '10px',
        width                    : '100%',
    },

    visibleIcon: {
        position                : 'absolute',
        left                    : '84%'
    },
}));

export default useStyles;