import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors'

const useCommonStyles = makeStyles(theme => ({
  root: {
    display         : 'flex',
    margin          : '0px 20px 20px 95px',
    justifyContent  : 'center',
    alignItems      : 'center',
    backgroundColor : colors.WHITE,
    boxShadow       : `1px 2px 9px ${colors.LIGHT_GRAY}`,
    height          : 'auto',
    width           : '94%'
  },

 

}));

export default useCommonStyles;