import { sessionService } from 'redux-react-session';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import action from './action';
import { userAdminRequest, userRequest } from '../../service/requests';

/////////////////////////
// reqAuthenticate()
/////////////////////////
function* reqAuthenticate({ payload }) {
  const { user, token } = payload;

  try {
    console.log('reqAuthenticate()');

    yield sessionService.saveSession({
      token
    });

    yield sessionService.saveUser({
      user: user,
      isSignedIn: false
    });
  } catch (error) {
    console.log('error : ', error);
  }
}

////////////////////////
// reqAdminSignIn()
///////////////////////
function* reqAdminSignIn({ payload }) {
  const { user, token } = payload;

  try {
    console.log('reqAdminSignIn()');

    yield sessionService.saveSession({
      token
    });

    yield sessionService.saveUser({
      isSignedIn: true
    });

  } catch (error) {
    console.log("error", error);
  }
}
//
// /////////////////////////
// // reqUserSignIn()
// /////////////////////////
// function* reqUserSignIn({ payload }) {
//   const { user, token } = payload;
//
//   try {
//     console.log('reqUserSignIn()');
//
//     const userDetail = {
//       user : user,
//       settings : {
//         notification : {
//           isEnabled : true,
//           custom : {
//             alert : true,
//             chat  : true,
//             voice : true,
//             video : true
//           }
//         }
//       }
//     }
//     const session = yield sessionService.saveSession({
//       token
//     });
//
//     const response = yield userRequest.getDetail({
//       user_id : user.userId
//     });
//
//     if (response) {
//       userDetail.userField = response.data.userDetail.userField
//
//       yield sessionService.saveUser({
//         ...userDetail,
//         isSignedIn: true
//       });
//       yield put({
//         type    : action.USER_FIELD_UPDATE,
//         payload : response.data.userDetail.userField
//       });
//       yield put({
//         type    : action.USER_SUBSCRIPTION_UPDATE,
//         payload : response.data.userDetail.userSubscription
//       });
//     }
//   } catch (error) {
//     console.log('error : ', error);
//   }
// }

/////////////////////////
// reqUserUpdateSession()
/////////////////////////
function* reqUserUpdateSession({ payload }) {
  const { user, userField } = payload;

  try {
    console.log('reqUserUpdateSession()');

    const getSession = state => state.Session;
    const Session = yield select(getSession);
    const userDetail = {
      ...Session.user,
      user: user,
      userField: userField
    }

    sessionService.saveUser(userDetail).then(() => {
      console.log('SAVE USER SESSION SUCCESS');
    }).catch(error => {
      console.log('error : ', error);
    });

  } catch (error) {
    console.log('error : ', error);
  }
}

/////////////////////////
// reqUserUpdateNotificationSettings()
/////////////////////////
function* reqUserUpdateNotificationSettings({ payload }) {
  const { notificationSetting, value } = payload;

  try {
    console.log('reqUserUpdateNotificationSettings()');

    const getSession = state => state.Session;
    const Session = yield select(getSession);
    let notificationSettings = Session.user.settings.notification;


    if (notificationSetting === 'desktop') {
      notificationSettings['isEnabled'] = value;
    } else {
      notificationSettings.custom[notificationSetting] = value;
    }

    const updatedSession = {
      ...Session.user,
      settings: {
        ...Session.user.settings,
        notification: notificationSettings
      }
    }

    sessionService.saveUser(updatedSession).then(() => {
      console.log('SAVE USER SESSION SUCCESS');
    }).catch(error => {
      console.log('error : ', error);
    });
  } catch (error) {
    console.log('error : ', error);
  }
}

export function* getUserAdminPermission(request) {

  try {
    const payload = request.payload
    const response = yield call(() => userAdminRequest.getUserAdminPermission(payload));
    const { data } = response;
    
    let permissions = [];
    if (data.roles) {
      let tempPermissions = [];
      data.roles.forEach(r => r?.permissions?.forEach(p => tempPermissions.push(p?.code)));
      permissions = [... new Set(tempPermissions)]
    }
    
    yield put(action.setAdminPermission(permissions));

  }
  catch (error) {
    console.log('error : ', error);
  }
}

export default function* rootSaga() {

  yield all([
    takeEvery(action.ADMIN_SIGNIN, reqAdminSignIn),
    takeLatest(action.GET_USER_ADMIN_PERMISSION, getUserAdminPermission)
  ]);
}
