
/* * * * * * * * * * * * * *
 * WEBAPP CONSTANTS
 * * * * * * * * * * * * * */

import { AlignHorizontalLeft, AlignHorizontalLeftOutlined } from "@material-ui/icons";
import { right } from "@popperjs/core";

// CONFIG HEADERS
export const XML_HTTP_REQUEST                   = 'XMLHttpRequest';
export const CSRF_TOKEN                         = 'nocheck';


// STATUS 
export const STATUS_ACTIVE                      = 'ACT';
export const STATUS_INACTIVE                    = 'INA';
export const STATUS_BLOCKED                     = 'BLO';
export const STATUS_SENT                        = 'SEN';
export const STATUS_RECEIVED                    = 'REC';

// ERROR
export const ALERT_SEVERITY_ERROR               = 'error';
export const ALERT_SEVERITY_INFO                = 'info';
export const ALERT_SEVERITY_SUCCESS             = 'success';
export const ALERT_SEVERITY_WARNING             = 'warning';

export const ALERT = {
  SUCCESS: 'success',
  INFO   : 'info',
  WARNING: 'warning',
  ERROR  : 'error'
};

export const PERMISSION = {
  HOME: {
    VIEW: 'HOM_VIEW'
  },

  SEARCH: {
    VIEW: 'SRC_VIEW'
  },

  ADMIN: {
    VIEW    : 'ADM_VIEW',
    CREATE  : 'ADM_CREATE',
    UPDATE  : 'ADM_UPDATE',
    DELETE  : 'ADM_DELETE',
  },

  ROLES: {
    VIEW    : 'RLS_VIEW',
    CREATE  : 'RLS_CREATE',
    UPDATE  : 'RLS_UPDATE',
    DELETE  : 'RLS_DELETE',
    ASSIGN  : 'RLS_ASSIGN',
    REMOVE  : 'RLS_REMOVE',
  }
};

// TOAST MESSAGES
export const TOAST_UPDATE_SUCCESSFULLY          = 'Updated successfully.';
export const TOAST_PASSWORD_MISMATCH            = 'Password mismatched.';
export const TOAST_PASSWORD_IS_WEAK             = 'Password is weak. Create a stronger password.';
export const TOAST_ADMIN_ADDED                  = `User successfully added.`;
export const TOAST_ADMIN_ERROR                  = `Please fill in all the required fields.`;
export const TOAST_CHANGE_EMAIL_SUCCESS         = 'You successfully change your email.';
export const TOAST_ROLE_ADDED                   = (roleName) => `${roleName} is successfully created.`;
export const TOAST_ROLE_UPDATE                  = (roleName) => `${roleName} is successfully update.`;
export const TOAST_ROLE_DELETE                  = (roleName) => `${roleName} is successfully deleted.`;
export const TOAST_AXIOS_REQUEST_ERROR          = 'Sorry, an error occurred.';
export const TOAST_INTERNAL_SERVER_ERROR        = 'Sorry, an error occurred.';
export const TOAST_PASSWORD_CHANGED             = 'Password is successfully changed.';
export const TOAST_VERIFICATION_CODE_SENT       = 'New verification code sent via text message.';
export const TOAST_PERMISSION_UPDATE_SUCCEED    = `Permissions updated.`;
export const TOAST_PERMISSION_UPDATE_FAILED     = `Permissions failed to update.`;

export const TOAST = {
  REQUEST_ERROR: 'Something went wrong please try again later.'
};


export const HTTP_STATUS = {
  // Standard HTTP Status Codes
  _200: '200',
  _201: '201',
  _202: '202',
  _203: '203',
  _204: '204',

  _400: '400',
  _401: '401',
  _403: '403',
  _404: '404',
  _405: '405',
  _409: '409',
  _422: '422',
  _500: '500',

  // Custom HTTP Status Codes
  _460: '460',
  _461: '461',
  _462: '462',
  _463: '463',
  _464: '464',
  _465: '465',
  _466: '466',
  _467: '467',
  _468: '468',
  _469: '469',
  _470: '470',
  _471: '471',
  _472: '472',
  _473: '473',
  _475: '475',
  _476: '476',
  _477: '477',
  _489: '489',
  _485: '485',
  _490: '490',
};


// EMPTY CONTENT
export const NO_USERS_FOUND_TITLE               = 'No Users Found.';
export const NO_USERS_FOUND_DESC                = `Patience grasshopper. \nYour Significant Other is out there.`;
export const NO_USERS_LIKED_ME_TITLE            = 'No One has Liked Your Profile.';
export const NO_USERS_LIKED_ME_DESC             = 'Users who liked you will appear here.';
export const NO_USERS_VIEWED_ME_TITLE           = 'No One has Viewed Your Profile.';
export const NO_USERS_VIEWED_ME_DESC            = 'Users who viewed you will appear here.';
export const NO_USERS_I_VIEWED_TITLE            = 'You Haven\’t Viewed Anyone Lately.';
export const NO_USERS_I_VIEWED_DESC             = 'Users you viewed will appear here.';

export const EMPTY_CHAT                         = 'EMPTY_CHAT';
export const EMPTY_UNANSWERED_MESSAGES          = 'EMPTY_UNANSWERED_MESSAGES';
export const EMPTY_UPCOMING_EVENTS              = 'EMPTY_UPCOMING_EVENTS';
export const EMPTY_USERS_SEARCH_HOME            = 'EMPTY_USERS_SEARCH_HOME';
export const EMPTY_USERS_SHORTLIST_HOME         = 'EMPTY_USERS_SHORTLIST_HOME';

export const EMPTY_ROLE_NAME                    = 'Please enter a role name first.';

//const added 
export const NO_CALL_HISTORY_DESC               = 'NO_CALL_HISTORY_DESC'; 
export const NO_CALL_HISTORY_TITLE              = 'NO_CALL_HISTORY_TITLE'; 
export const NO_CHAT_HISTORY_DESC               = 'NO_CHAT_HISTORY_DESC'; 
export const NO_CHAT_HISTORY_TITLE              = 'NO_CHAT_HISTORY_TITLE'; 
export const NO_NOTIFICATION_DESC               = 'NO_NOTIFICATION_DESC'; 
export const NO_NOTIFICATION_HISTORY_TITLE      = 'NO_NOTIFICATION_HISTORY_TITLE';

export const FONT                               = 'Open Sans, sans-serif';

export const TOTAL_USER                         = 'Total Users';
export const REGULAR_USER                       = 'Regular Users';
export const PRIVATE_USER                       = 'Private Users';
export const ELITE_USER                         = 'Elite Users';
export const MALE_USER                          = 'Male Users';
export const FEMALE_USER                        = 'Female Users';

export const PRELOADER_CARD_VIEW                = 'PRELOADER_CARD_VIEW';
export const PRELOADER_CARD_NEAR_ME             = 'PRELOADER_CARD_NEAR_ME';
export const PRELOADER_CARD_SHORTLIST           = 'PRELOADER_CARD_SHORTLIST';
export const PRELOADER_CARD_SHORTLIST_HOME      = 'PRELOADER_CARD_SHORTLIST_HOME';
export const PRELOADER_CARD_USER                = 'PRELOADER_CARD_USER';
export const PRELOADER_CARD_CALENDAR            = 'PRELOADER_CARD_CALENDAR';
export const PRELOADER_UPCOMING_EVENT_CARD      = 'PRELOADER_UPCOMING_EVENT_CARD';
export const PRELOADER_CALL_CARD                = 'PRELOADER_CALL_CARD';
export const PRELOADER_MESSAGE_CARD             = 'PRELOADER_MESSAGE_CARD';
export const PRELOADER_UNANSWERED_MESSAGE_CARD  = 'PRELOADER_UNANSWERED_MESSAGE_CARD';
export const PRELOADER_NOTIFICATION_CARD        = 'PRELOADER_NOTIFICATION_CARD';
export const PRELOADER_USER_PROFILE             = 'PRELOADER_USER_PROFILE';
export const PRELOADER_SAVED_SEARCH             = 'PRELOADER_SAVED_SEARCH';
export const PRELOADER_CARD_SEARCH_HOME         = 'PRELOADER_CARD_SEARCH_HOME';
export const PRELODAER_MESSAGE                  = 'PRELODAER_MESSAGE';
export const PRELOADER_USER_HISTORY             = 'PRELOADER_USER_HISTORY';
export const PRELOADER_REFERRAL_CODE            = 'PRELOADER_REFERRAL_CODE';
export const PRELOADER_BLOCKED_PROFILES         = 'PRELOADER_BLOCKED_PROFILES';
export const FILTER_SPECIAL_CHARACTERS          =           /[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const FILTER_SPECIAL_CHARACTERS_EMAIL    =        /[`~!#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi;



//////////////////////////////////////
/// Column Names for table in Search
/////////////////////////////////////
  export const SEARCH_TABLE_COLUMN = [
    { 
        field: 'userId', 
        headerName: 'USER ID',
        headerClassName: 'header-style',
        minWidth: 150,
    },
    {
      field: 'lastName',
      headerName: 'LAST NAME',
      headerClassName: 'header-style',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'firstName',
      headerName: 'FIRST NAME',
      headerClassName: 'header-style',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'gender',
      headerName: 'GENDER',
      headerClassName: 'header-style',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'birthday',
      headerName: 'BIRTHDAY',
      headerClassName: 'header-style',
      type: 'date',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'phoneNo',
      headerName: 'PHONE NUMBER',
      headerClassName: 'header-style',
      dataType: {type: 'number'},
      minWidth: 200,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'EMAIL ADDRESS',
      headerClassName: 'header-style',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'city',
      headerName: 'CITY',
      minWidth: 150,
      headerClassName: 'header-style',
      flex: 1
    },
    {
      field: 'state',
      headerName: 'STATE',
      headerClassName: 'header-style',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'country',
      headerName: 'COUNTRY',
      minWidth: 150,
      headerClassName: 'header-style',
      flex: 1
    },
    {
      field: 'viewStatus',
      headerName: 'CURRENT PLAN',
      headerClassName: 'header-style',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'subscriptionPlanEnd',
      headerName: 'LICENSE EXPIRATION',
      type: 'date',
      minWidth: 200,
      headerClassName: 'header-style',
      flex: 1
    }
  ]

//////////////////////////////////////
/// ADMIN_TABLE_COLUMN
/////////////////////////////////////
  export const ADMIN_TABLE_COLUMN = [
    { 
      headerClassName: 'header-style',
      field: 'userAdminId', 
      headerName: 'USER ID',
      minWidth: 150,
    },
    {
      headerClassName: 'header-style',
      field: 'lastName',
      headerName: 'LAST NAME',
      minWidth: 150,
      flex: 1
    },
    {
      headerClassName: 'header-style',
      field: 'firstName',
      headerName: 'FIRST NAME',
      minWidth: 150,
      flex: 1
    },
    {
      headerClassName: 'header-style',
      field: 'email',
      headerName: 'EMAIL ADDRESS',
      minWidth: 200,
      flex: 1
    },
    {
      headerClassName: 'header-style',
      field: 'whenAdded',
      headerName: 'CREATED ON',
      type: 'date',
      minWidth: 150,
      flex: 1
    },
    {
      headerClassName: 'header-style',
      field: 'roleTitle',
      headerName: 'ROLES',
      minWidth: 200,
      flex: 1
    },
    {
      headerClassName: 'header-style',
      field: 'lastLogin',
      headerName: 'LAST LOG IN DATE/TIME',
      type: 'date',
      minWidth: 200,
      flex: 1
    },
       
  ];
