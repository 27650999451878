import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../utils/colors';


const useStyles = makeStyles(({
  modal: {
    display          : 'flex',
    flexDirection    : 'column',
    position         : 'absolute',
    top              : '50%',
    left             : '50%',
    transform        : 'translate(-50%, -50%)',
    backgroundColor  : colors.WHITE,
    width            : '720px',
    height           : 'auto',
    maxHeight        : '450px',
    overflowY        : 'auto'
  },

  closeIconContainer: {
    backgroundColor   : 'transparent',
  },

  title: {
    color             : colors.TEXT_BLACK,
    fontSize          : '20px',
    fontWeight        : 'bold',
    textAlign         : 'left',
    paddingTop        : '30px',
    paddingLeft       : '35px',
  },

  closeButton: {
    color             : colors.GRAY,
    float             : 'right',
    margin            : '10px 20px 0px 0px',
  },

  closeModal: {
    position          : 'absolute',
    top               : '15px',
    right             : '15px',
    padding           : '0px 1px',
    cursor            : 'pointer',
    backgroundColor   : 'transparent',
    color             : 'gray',
  },

}));

export default useStyles;