import React, { useEffect, useState } from 'react'
import Accordion from '@material-ui/core/Accordion';
import { Search, 
        Edit,
        Add,
        Delete,
        Check,
        PlayArrow,
        CheckBox,
        CheckBoxOutlineBlank,
        IndeterminateCheckBox,
        AdminPanelSettingsRounded }  from '@material-ui/icons/';
import PrimaryButton   from "../../components/buttons/primary-button";
import LoadingButton                 from '../../components/buttons/loading-button';  
import SecondaryButton               from "../../components/buttons/secondary-button";
import {
  Button,
  Container,
  Typography,
  Box,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Chip,
  InputAdornment,
  TextField,
  Tooltip }                           from '@material-ui/core';
import useStyles                      from './style';
import { userAdminRequest }           from '../../service/requests';
import { handleHttpError }            from '../../utils/helper';
import { 
  showSuccessAlert,
  showWarningAlert,
  showFailedAlert }                   from '../../utils/alerts';
import {
  TOAST_ROLE_ADDED,
  TOAST_ROLE_UPDATE,
  TOAST_ROLE_DELETE,
  TOAST_PERMISSION_UPDATE_SUCCEED,
  TOAST_PERMISSION_UPDATE_FAILED,
  TOAST_INTERNAL_SERVER_ERROR,
  EMPTY_ROLE_NAME,
  FILTER_SPECIAL_CHARACTERS }         from '../../utils/constants';
import NewRoleModal                   from './new-role-modal';
import DeleteRoleModal                from './delete-role-modal';
import UpdateRoleModal                from './update-role-modal';
import useCommonStyles                from '../../components/common/style';
import {
  withRoleDeletePermission,
  withRoleAddPermission,
  withRoleEditPermission }            from "../../utils/with-permissions";

const DeleteButton = withRoleDeletePermission(Button);
const AddButton = withRoleAddPermission(Button);
const UpdateButton = withRoleEditPermission(Button);

const Roles = ({ showAlert }) => {

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [value, setValue] = useState('administrator');
  const [roles, setRoles] = useState([]);
  const [permissionCategory, setPermissionCategory] = useState([]);
  const [rolePermission, setRolePermission] = useState();
  const [permissions, setPermissions] = useState([]);
  const [isNewRoleModalOpen, setIsNewRoleModalOpen] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
  const [affectedAdmins, setAffectedAdmins] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isUpdateRoleModalOpen, setIsUpdateRoleModalOpen] = useState(false);

  const [isEditTooltipShow, setIsEditTooltipShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteTooltipShow, setIsDeleteTooltipShow] = useState(false);
  const [isCommandToolShow, setIsCommandToolShow] = useState(false);
  const [permissionLength, setPermissionLength] = useState([]);

  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    userAdminRequest.getAllRoles().then(response => {
      console.log("getAllRoles response", response)
      if (response.status === 200) {
        setRoles(response.data.roles)
      }
    });
    userAdminRequest.getAllPermissions().then(response => {
      console.log("getAllPermissions response", response)
      if (response.status === 200) {
        setPermissionCategory(response.data.permission_category);
        let tempPermissions = [];
        response.data.permission_category.map(category => {
          for (const p of category.permissions) {
            tempPermissions.push({ permissionId: p.permissionId, isChecked: false, title: category.category.title + " - " + "[" + p.title + "]" });
          }
        });
        console.log("tempPermissions", tempPermissions);
        setPermissions(tempPermissions);
      }
    });
  }, []);

  useEffect(() => {
    console.log("rolePermission has been changed");
    if (rolePermission != null) {
      let tempPermissions = [...permissions];
      tempPermissions = tempPermissions.map(permission => {
        return { ...permission, isChecked: false };
      });
      let adminCount = 0, homeCount = 0, searchCount = 0, managementCount = 0, rolesCount = 0;
      let tempLength = [];
      rolePermission.permissions.map(permission => {
        let index = tempPermissions.findIndex(p => p.permissionId == permission.permissionId);

        tempPermissions[index].title.includes('Admin') && adminCount++
        tempPermissions[index].title.includes('Home') && homeCount++
        tempPermissions[index].title.includes('Search') && searchCount++
        tempPermissions[index].title.includes('Management') && managementCount++
        tempPermissions[index].title.includes('Roles') && rolesCount++

        tempPermissions[index] = { ...tempPermissions[index], isChecked: true };
      });

      tempLength.push(homeCount, searchCount, managementCount, adminCount, rolesCount )
      setPermissions(tempPermissions);
      setPermissionLength(tempLength)
      userAdminRequest.getRoleAdmins({ roleid: rolePermission.role.roleId })
        .then(response => {
          console.log("getRoleAdmins response", response);

          if (response.status == 200) {
            setAffectedAdmins(response.data.userAdmin);
          }
        });

      const roleIndex = roles.findIndex(role => { return role.roleId == rolePermission.role.roleId });
      let newRoles = [...roles];
      newRoles[roleIndex] = rolePermission.role;
      setRoles(newRoles);
    }
  }, [rolePermission]);

  /////////////////////////
  // handleCheckboxChange
  /////////////////////////
  const handleCheckboxChange = (permissionId) => {
    if (rolePermission != null) {
      console.log("permissionId", permissionId);
      let newPermissions = [...permissions];
      newPermissions = newPermissions.map(permission => {
        if (permission.permissionId == permissionId) {
          return { ...permission, isChecked: !permission.isChecked }
        } else {
          return { ...permission }
        }
      });
      let strPermissions = "";
      for (let index = 0; index < newPermissions.length; index++) {
        let permission = newPermissions[index];
        if (permission.isChecked) {
          strPermissions += (permission.permissionId + ",")
        }
      }
      const newDataRole = {
        id: rolePermission.role.roleId,
        title: rolePermission.role.title,
        description: rolePermission.role.description,
        permissions: strPermissions
      };
      console.log("newDataRole", newDataRole);
      userAdminRequest.updateRole(newDataRole).catch(err=>{
        handleHttpError(err, "userAdminRequest.updateRole")
      }).then(response => {
        console.log("response", response);
        if (response.status == 200) {
          setRolePermission(response.data.role_permission);
          showSuccessAlert(TOAST_PERMISSION_UPDATE_SUCCEED);
        } else {
          showFailedAlert(TOAST_PERMISSION_UPDATE_FAILED);
        }
      });
    }
  }

  ///////////////////////////
  // handleOnChangeRoleName
  ///////////////////////////
  const handleOnChangeRoleName = (event) => {
    let tempRoleName = event.target.value.replace(FILTER_SPECIAL_CHARACTERS, '');
    setRoleName(tempRoleName);
  };

  ///////////////////////
  // handleSaveRole
  ///////////////////////
  const handleOnSaveNewRole = () => {
    console.log("handleOnSaveNewRole", roleName);

    if (roleName !== "") {
      const data = {
        title: roleName,
        description: "",
        permissions: "",
      }
      userAdminRequest.createRole(data).catch(err=>{handleHttpError(err, TOAST_INTERNAL_SERVER_ERROR)})
      .then(response => {
        if (response.status === 200) {
          showSuccessAlert(TOAST_ROLE_ADDED(roleName));
          setRoleName('');
          setIsNewRoleModalOpen(false);
          setRolePermission(response.data.role_permission);
          setSelectedRole(response.data.role_permission.role.roleId);

          userAdminRequest.getAllRoles().then(response => {
            console.log("response", response)
            if (response.status === 200) {
              setRoles(response.data.roles)
            }
          });


        } else {
          showFailedAlert(TOAST_INTERNAL_SERVER_ERROR);
        }
      });
    } else {
      showFailedAlert(EMPTY_ROLE_NAME);
    }
  }

  //////////////////////////////
  // handleOnSaveUpdateRoleName
  //////////////////////////////
  const handleOnSaveUpdateRoleName = () => {
    console.log('handleOnSaveUpdateRoleName', roleName);

    if(roleName !== null){
      setIsLoading(true)
      let newPermissions = [...permissions];
      let strPermissions = "";
      for (let index = 0; index < newPermissions.length; index++) {
        let permission = newPermissions[index];
        if (permission.isChecked) {
          strPermissions += (permission.permissionId + ",")
        }
      }
      const updateDataRole = {
        id: rolePermission.role.roleId,
        title: roleName,
        description: rolePermission.role.description,
        permissions: strPermissions
      };
      console.log("updateDataRole", updateDataRole);
      userAdminRequest.updateRole(updateDataRole).catch(err=>{
        handleHttpError(err, "userAdminRequest.updateRole")
      })
      .then(response=>{
        console.log("response", response);
        if (response.status == 200) {
          setIsEditing(false)
          setRolePermission(response.data.role_permission);
          showSuccessAlert(TOAST_ROLE_UPDATE(response.data.role_permission.role.title));
        }

        setIsLoading(false)
        setRoleName('');
        setIsUpdateRoleModalOpen(false);
      });
    }
  }

  const handleOnSaveDeleteRole = () => {
    console.log("handleOnSaveDeleteRole()");
    if (rolePermission != null) {
      userAdminRequest.deleteRole({
        id: rolePermission.role.roleId
      }).then(response => {
        console.log("response", response);
        if (response.status == 200) {
          showSuccessAlert(TOAST_ROLE_DELETE(rolePermission.role.title));
          setRolePermission(null);
          setIsDeleteRoleModalOpen(false);
          userAdminRequest.getAllRoles().then(response => {
            console.log("response", response)
            if (response.status === 200) {
              setRoles(response.data.roles)
            }
          });
          userAdminRequest.getAllPermissions().then(response => {
            console.log("response", response)
            if (response.status === 200) {
              setPermissionCategory(response.data.permission_category);
              let tempPermissions = [];
              response.data.permission_category.map(category => {
                for (const p of category.permissions) {
                  tempPermissions.push({ permissionId: p.permissionId, isChecked: false, title: category.category.title + " - " + "[" + p.title + "]" });
                }
              });
              console.log("tempPermissions", tempPermissions);
              setPermissions(tempPermissions);
              setSelectedRole(null);
              setPermissionLength([]);
            }
          });
        }
      });
    }
  }
  ///////////////////////
  // addRoleHandle
  ///////////////////////
  const addRoleHandle = (event) => {
    console.info('addRole()');
    setRoleName('');
    setIsEditing(false);
    setIsNewRoleModalOpen(true);
  };
  ///////////////////////
  // deleteRoleHandle
  ///////////////////////
  const deleteRoleHandle = (event) => {
    console.info('deleteRole()');
    if (rolePermission != null) {
      setIsDeleteRoleModalOpen(true);
    }
  };
  {/* Update Roles Handle*/ }
  const updateRoleHandle = (event) => {
    console.info('updateRole()');
    setIsUpdateRoleModalOpen(true);
  };
  {/* Chip Handle*/ }
  const chipHandleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  {/* RadioButtons Roles Handle*/ }
  const radioButtonsHandleChanges = (index) => {
    setValue(index);
    const roleId = index;
    userAdminRequest.getRole({ roleid: roleId }).then(response => {
      setRolePermission(response.data.role_permission);
    });
  };


  return (
    <div className={commonClasses.root}>
      <Box className={classes.rolesBox}>
        <Box style={{display:'flex', flex: 1, flexDirection: 'row'}}>

          <Box className={classes.firstBox}>
            <Box className={classes.labelBox}>
              <Typography variant='h2' className={classes.labelText}>Roles</Typography>
              {/* No Search Function yet */}
              <TextField
                placeholder='Search'
                variant = 'outlined'
                size='small'
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={classes.adornment} position='start' disablePointerEvents={true}>
                      <Search />
                    </InputAdornment>
                  )
                }}
              /> 
            </Box>

            <Box className={classes.roleListBox}>
              {
                roles.map((role) => {
                  return (
                    <Box className={classes.roleLists} onMouseEnter={() => {selectedRole === role.roleId && setIsCommandToolShow(true)}} onMouseLeave={() => { selectedRole === role.roleId && setIsCommandToolShow(false)}}>
                      {selectedRole === role.roleId && isEditing  &&           
                        <Tooltip title={`Set a new Role name for ${role.title}`}>
                          <TextField
                            className={classes.nameTxtField}
                            size='small'
                            id='outlined-basic'
                            variant='outlined'
                            autoFocus
                            value={roleName}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <AdminPanelSettingsRounded/>
                                </InputAdornment>
                              )
                            }}
                            onChange={ (event) => { handleOnChangeRoleName(event) } }
                          />
                        </Tooltip>
                      }
                      { (!isEditing || selectedRole != role.roleId)  && <Typography className={`${classes.roleTitle} ${selectedRole === role.roleId && classes.selectedRole}`}
                         onClick={ () => { 
                            setIsCommandToolShow(true)
                            radioButtonsHandleChanges(role.roleId)
                            setSelectedRole(role.roleId)}}> {`${role.title} (${role.permissionCount})`} </Typography>
                      }
                      { selectedRole === role.roleId && !isEditing && 
                        <Box className={classes.spanBox}>
                            {!isCommandToolShow && <Check className={`${classes.editButton}`}/>}
                            {isCommandToolShow &&
                            <>
                              <Tooltip title ='Edit' open={isEditTooltipShow}>
                                <Edit className={`${classes.editButton}`}
                                      onMouseEnter={() => {setIsEditTooltipShow(true); setRoleName(role.title)}}
                                      onMouseLeave={() => setIsEditTooltipShow(false)}
                                      onClick={ () => setIsEditing(true) } /> 
                              </Tooltip> 

                              <Tooltip title ='Delete' open={isDeleteTooltipShow}>
                                <Delete className={`${classes.removeButton}`}
                                        onMouseEnter={() => setIsDeleteTooltipShow (true)}
                                        onMouseLeave={() => setIsDeleteTooltipShow (false)}
                                        onClick={deleteRoleHandle} />
                              </Tooltip>
                            </>
                            }
                        </Box>
                      }
                    </Box>
                  )
                })
              }
            </Box>
          </Box>

          <Box className={classes.secondBox}>
            <Box className={classes.permissionLabelBox}>
               <Typography variant='h2' className={classes.labelText}>Permissions</Typography>
            </Box>
            <Box className={classes.accordionBox} >
            {
              permissionCategory.map((category, index) => {
              
                return (
                  <Accordion disabled={selectedRole === null} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index} elevation={0} className={classes.accordion}>

                    <AccordionSummary>
                      <PlayArrow style={{paddingRight: '10px'}}/>
                      { selectedRole === null ? <CheckBoxOutlineBlank/> : category.permissions.length === permissionLength[index] && category.permissions.length !== 0 ? <CheckBox style={{color:'#F47F34'}}/> : category.permissions.length !== 0 ? <IndeterminateCheckBox style={{color: '#B5B5B5'}}/> : <CheckBoxOutlineBlank/> }
                      <Typography style={{paddingLeft: '10px'}}>{category.category.title}</Typography>
                    </AccordionSummary>       
                    <AccordionDetails className={classes.accordionDetails}>
                      {                   
                        category.permissions.map((permission, index) => {
                          const updatedPermission = permissions.find(p => permission.permissionId == p.permissionId);
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  onChange={() => {
                                    if (updatedPermission != undefined) {
                                      handleCheckboxChange(updatedPermission.permissionId);
                                    }
                                  }}
                                  checked={updatedPermission != undefined ? updatedPermission.isChecked : false}
                                  name="checkedB"
                                  color="primary" />

                              }
                              label={permission.title} />
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
            </Box>
            <Box style={{display:'flex', flex: 1.5, }}/>
          </Box>

          <Box style={{display:'flex', flexDirection: 'column', flex: 1 }} >
            <Box className={classes.thirdBox}>
              <PrimaryButton
                className={classes.addButton}
                onClick={ addRoleHandle }
                startIcon={<Add />}>
                  New Role
              </PrimaryButton> 
            </Box>
            <Box className={classes.permissionListBox}>
              <Container style={{margin: '30px 0px 20px 30px', overflow: 'hidden',}}>
                  {
                    permissions.map((permission, index) => {
                      if (permission.isChecked) {
                        return (
                          <Chip key={index} color="primary" label={permission.title} className={classes.chip} />
                        )
                      }
                    })
                  }
              </Container>  
            </Box>
            <Box className={classes.saveBtnBox}>
              { isEditing && <SecondaryButton onClick  = { () => {setIsEditing(false); setRoleName('')} } className= {classes.cancelBtn}>
                <Typography>{`Cancel`}</Typography>
              </SecondaryButton>}
              <LoadingButton
                disabled  = { !isEditing || roleName == '' || roleName == rolePermission?.role.title }
                type      = 'submit'
                onClick   ={ handleOnSaveUpdateRoleName }
                isLoading = { isLoading }
                className = { classes.saveBtn }>
                  { 'Save' }    
              </LoadingButton>
            </Box>
          </Box>

        </Box>
      </Box>

      <DeleteRoleModal
        isVisible={isDeleteRoleModalOpen}
        onClose={() => setIsDeleteRoleModalOpen(false)}
        selectedRole={rolePermission}
        affectedAdmins={affectedAdmins}
        onSave={handleOnSaveDeleteRole} />
      <NewRoleModal
        roleName={roleName}
        setRoleName={setRoleName}
        isVisible={isNewRoleModalOpen}
        onClose={() => setIsNewRoleModalOpen(false)}
        onSave={handleOnSaveNewRole} />
      <UpdateRoleModal
        currentRole={rolePermission?.role.title}
        setRoleName={handleOnChangeRoleName}
        isVisible={isUpdateRoleModalOpen}
        onClose={()=> setIsUpdateRoleModalOpen(false)}
        onSave={handleOnSaveUpdateRoleName} />
    </div>    
  )
}

export default Roles;