const colors = {
  EMBER                      : '#A40030',
  GREEN                      : 'green',
  HOVER_DEFAULT              : '#F5F5F5',
  HOVER_LIGHT_ORANGE         : '#FFC787',
  LIGHT_GRAY                 : 'lightgray',
  LIGHT_GREEN                : '#92D050',
  LIGHT_ORANGE               : '#FFDFBA',
  RED                        : '#FF0000',
  TEAL                       : '#00C0BF',
  TEXT_BLACK                 : '#050505',
  TEXT_GRAY                  : '#65676B',
  YELLOW                     : '#FFC700',
  MEDIUM_SEA_GREEN           : '#67C35F',
  TOMATO_RED                 : '#EF5350',
  
  // Updated Colors
  // Common
  BLACK                      : '#000000',
  WHITE                      : '#FFFFFF',
  GRAY                       : '#989795',
  DARK_GRAY                  :'#7B8794',
  LIGHT_GRAY                 : '#C4C4C4',

  // Theme
  GRADIENT_YELLOW            : '#FDC830', // Top color for gradient
  // GRADIENT_YELLOW_ORANGE        : '#F69940', // Middle color for gradient
  GRADIENT_ORANGE            : '#F37335', // Lower color for gradient
  PRIMARY                    : '#FA803D',
  PRIMARY_TEXT               : '#707070',
  ERROR                      : '#E3394C',

  PRELOADER_BACKGROUND       : '#EFF1F6',
  PRELOADER_SHADOW           : '#E0E0E0',
  BACKGROUND_COLOR           : '#F5F4F5',
  BACKGROUND_COLOR_NOT_FOUND : '#EEEFF0',
  TRANSPARENT                : 'transparent',
  USER_PROFILE_BLOCKED       : '#989795',
  
  CALENDAR_RESPONSE_ACCEPTED : '#1CBF22',
  CALENDAR_RESPONSE_PENDING  : '#B2B2B2',

  FACEBOOK                   : '#3b5998',
  TWITTER                    : '#00acee',
  LINKED_IN                  : '#0e76a8',

  DASHBOARD_VIEWED_ME        : '#CDEAFE',
  DASHBOARD_CALENDAR_INVITES : '#D0FFD9',
  DASHBOARD_HEARTED_ME       : '#FFCCD2',
  DASHBOARD_PROFILE_PROGRESS : '#A9D18E',
  DASHBOARD_PROFILE_IDEAL    : '#F8CBAD',

  ABOUT_US_SECTION_TITLE     : '#365E9F',

  //Sidebar Navigation
  SIDENAV_ORANGE             : '#F47F34',
  SIDENAV_ICON               : '#8A8A8A',
  SIDENAV_TEXT               : '#373737'

}

export default colors
