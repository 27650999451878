import React from 'react';
import { Router } from 'react-router-dom';
import { 
  MuiThemeProvider, 
  createTheme
} from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import Routes from '../src/routers/index';
import colors from './utils/colors';
import '../src/assets/App.css';
import { disableLogs } from './utils/helper';
import { FONT } from './utils/constants';
import 'typeface-open-sans';

const browserHistory = createBrowserHistory();
const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
      contrastText: colors.WHITE,
      border: 'rgba(0,0,0,0.1)'
    },
    secondary: {
      main: colors.WHITE,
    },
    text: {
      primary: colors.PRIMARY_TEXT,
      // secondary: colors.WHITE,
    },
    chat: {
      border: 'rgba(0,0,0,0.5)'
    }
  },
  typography: {
    fontFamily: FONT,
    button: {
      textTransform: 'none',
    },
  },
});

const App = () => {
  disableLogs();

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;