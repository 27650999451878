import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core/';
import { useHistory } from 'react-router-dom';

import useStyles from './style';
import logo from '../../assets/images/logo_not_found.png'
import johnnyLee from '../../assets/images/johnny_lee.png'
import colors from '../../utils/colors';

const Restricted = () => {
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    document.getElementById('root').style.height = '100%';
  }, []);

  const goToHompage = () => {
    history.push('');
  };

  return (
    <Box bgcolor={colors.BACKGROUND_COLOR_NOT_FOUND} height='100%' display='flex' flexDirection='row'>
      <Box width='60%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' px={10}>
        <Typography variant='h4' color='primary' className={classes.title} align='right'>
            You don't have access to this page
        </Typography>

      </Box>
      <Box height='100%' width='40%' display='flex' flexDirection='column'>
        <img src={logo} className={classes.logo} alt='S.O.Search'/>
        <img src={johnnyLee} className={classes.johnnyLee} alt='Johnny Lee'/>
      </Box>
    </Box>
  )
};

export default Restricted;