import React, { useState }          from 'react';
import { Button, CircularProgress } from '@material-ui/core/'

const LoadingButton = ({ children, text, isLoading, onClick, className, type, disabled }) => {

  return (
      <Button type={ type && type } className={ className } onClick={onClick} disabled={ isLoading || disabled} size='medium' variant='contained' color='primary'>
        {
          isLoading ? <CircularProgress size={20} color='secondary'/> : <>{text} {children}</>
        }
      </Button>
  )
};
export default LoadingButton;