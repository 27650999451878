import React, { useState, useEffect, useCallback }               from 'react';
import { connect }                                  from 'react-redux';
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress
} from '@material-ui/core';
import SearchIcon                                   from '@material-ui/icons/Search';
import { DataGrid }                                 from '@material-ui/data-grid';

import AddIcon                                      from '@material-ui/icons/Add';
import { debounce }                                 from 'lodash';
import rootReducer                                  from '../../redux/action'
import useStyles                                    from './style';
import useCommonClasses                             from '../../components/common/style'
import NO_USERS_FOUND                               from "../../assets/images/no_users_found.png";
import {
  ADMIN_TABLE_COLUMN,
  ALERT_SEVERITY_SUCCESS,
  TOAST_ADMIN_ADDED,
  ALERT_SEVERITY_ERROR,
  TOAST_ADMIN_ERROR,
  ALERT, TOAST,
  HTTP_STATUS,
  FILTER_SPECIAL_CHARACTERS_EMAIL,
  FILTER_SPECIAL_CHARACTERS
} from '../../utils/constants';
import NextBeforeButtons                         from '../../components/table/next-before-buttons/index';
import { handleHttpError, getFormattedDateTime } from '../../utils/helper';
import { userRequest, userAdminRequest }         from '../../service/requests';
import NewAdminModal                             from './new-admin-modal';
import { showFailedAlert }                       from "../../utils/alerts";
import Empty                                     from '../../components/empty';
import { showSuccessAlert , showWarningAlert}    from '../../utils/alerts';
import PrimaryButton                             from "../../components/buttons/primary-button";


const { signOutUser } = rootReducer;

const Admin = (props) => {

  const { user, showAlert } = props

  const [ inputValue, setInputValue ]                      = useState('');
  const [ isModalNewAdminVisible, setIsModalAdminVisible ] = useState(false);
  const [ keyword, setkeywordSearch ]                      = useState('');
  const [ adminUser, setAdminUser ]                        = useState([]);
  const [ page, setPagination ]                            = useState(1);
  const [ allRoles, setAllRoles ]                          = useState();
  const [ isEmailExisting, setIsEmailExisting ]            = useState(false);
  const [ isPasswordNotMatch, setIsPasswordNotMatch ]      = useState(false);
  const [ totalPage, setTotalPage ]                        = useState(1);
  const [ isLoading, setIsLoading ]                        = useState(false);
  const [ debouncedCallApi, setDeBouncedCallApi ]          = useState();
  const [eventDetails, setEventDetails]                    = useState({
      roleId          : [],
      email           : '',
      firstName       : '',
      LastName        : '',
      password        : '',
      confirmPassword : '',
    }
  );
  const classes       = useStyles();
  const commonClasses = useCommonClasses();

  console.log('Admin()');

  const debouncedSearch = useCallback(debounce(async (keyword, page)=>{
    const response = await userRequest.getAdminUsers({ keyword: keyword, page: page })
    .catch(err=>handleHttpError(err, 'userAdminRequest.getAllRoles'));

    if (response?.status == HTTP_STATUS._200){
      const pageCount = response?.data?.UserAdminWithPage?.pageCount?.count
      const adminUsers = response?.data?.UserAdminWithPage?.userAdmin?.map((user) => {
        let formattedWhenAdded = getFormattedDateTime(user?.userAdmin?.whenAdded, 'unix', 'YYYY-MM-DD');
        let formattedLastLogin = getFormattedDateTime(user?.userAdmin?.lastLogin, 'unix', 'YYYY-MM-DD hh:mm a');

        const roles = user?.roles.map((role) =>{
          const title = role.title
          return title
        });
        
      return {
            ...user.userAdmin,
            whenAdded   : formattedWhenAdded,
            lastLogin   : formattedLastLogin,
            roleTitle   : `${roles.join(', ')}`,
          };
      });
      setTotalPage(pageCount)
      setAdminUser(adminUsers);
    } else {
      showFailedAlert(TOAST.REQUEST_ERROR);
    }
    setIsLoading(false);
  },500));

  useEffect(()=>{
    debouncedSearch(keyword, page);
  }, []);

  useEffect(()=>{
    debouncedSearch(keyword, page);
  }, [keyword, page]);

  const toggleNewAdminModal = (state = !isModalNewAdminVisible) => {
    setIsModalAdminVisible(state)
  };

  ////////////////////////////
  // getAllRole()
  ////////////////////////////
  useEffect(() => {
    (async function() {
      const response = await userAdminRequest.getAllRoles().catch(err => {
        handleHttpError(err, 'userAdminRequest.getAllRoles')
      });
      if (response.data.status.code == HTTP_STATUS._200){
        const adminRoles = response?.data?.roles;
        setAllRoles(adminRoles);
      } else {
        console.log('userAdminRequest.getAllRoles error: ', response);
        showFailedAlert(TOAST.REQUEST_ERROR);
      }
    }());
  },[]);

  const onSave = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if(eventDetails.roleId != undefined && eventDetails.roleId != ''){
      if(eventDetails.password == eventDetails.confirmPassword){
        const response = await userAdminRequest.createAdmin({
          roles_id    : `${eventDetails.roleId}`,
          email       : eventDetails.email,
          first_name  : eventDetails.firstName,
          last_name   : eventDetails.LastName,
          password    : eventDetails.password,
          who_added   : user.userAdminId
        }).catch(err => handleHttpError(err, 'userAdminRequest.getAllRoles'));
        if (response.data.status.code == HTTP_STATUS._200){
          showSuccessAlert(TOAST_ADMIN_ADDED);
          setIsPasswordNotMatch(false);
          setIsModalAdminVisible(false);
        }else{
          setIsEmailExisting(true);
          setIsPasswordNotMatch(false);
        }
      }else{
        setIsEmailExisting(false);
        setIsPasswordNotMatch(true);
      }
    }else{
      showWarningAlert(TOAST_ADMIN_ERROR);
    }
    setIsLoading(false)
  }

  const handleChange = (event) => {
    const value = event?.target?.value.replace(FILTER_SPECIAL_CHARACTERS_EMAIL, '');
    const name = event?.target?.name;
    setEventDetails({...eventDetails,[name]: value})
    name == 'email' && setIsEmailExisting(false)
    name == 'password' || name == 'confirmPassword' && setIsPasswordNotMatch(false)
    console.log(value);
  }

  const handleRole = (roleData) => {
    const result = roleData?.map(roles => roles.roleId)
    setEventDetails({...eventDetails, roleId: result})
  }

  /////////////////////////
  ///Get Search inputed
  ///////////////////////
  const handlerSearchInput = (e) => {
    setInputValue(e.target.value.replace(FILTER_SPECIAL_CHARACTERS, ''));
    setkeywordSearch(e.target.value);
    setIsLoading(true);
  };

  /////////////////////////
  ///Reset Search Field
  ///////////////////////
  const resetSearchField = () => {
    setInputValue("");
    setPagination(1);
    setkeywordSearch("");
  };

  //////////////////////////
  ///Button Search Action
  ////////////////////////
  const onClickSearch = () => {
    setInputValue("");
    // getAdminUsers();
  };
  //////////////////////////////
  ///Enter Key Search Action
  ////////////////////////////
  const handlerKeyEnter = (event) => {
    if(event.key === 'Enter') {
      setInputValue('');
    }
  };

  const onSetPage = (page) => {
    setPagination(page)
  }

  const onPageNext = () => {
    setPagination(page + 1);
  };

  const onNextTenPages = () => {
    if( page >= totalPage - 10){
      setPagination(page + 10);
    }
    console.log(page);
  };

  const onPageBefore = () => {
    if(page != 1) {
      setPagination(page - 1);
    }
  };

  const onPreviousTenPages = () => {
    if(page <= 10){
      setPagination(page - 10);
    }
    console.log(page);
  };


  const onClose = () => {
    setIsModalAdminVisible(false)
    setIsPasswordNotMatch(false)
    setIsEmailExisting(false)
  };


  return (

    <div className={commonClasses.root}>

      <NewAdminModal
        isPasswordNotMatch  = { isPasswordNotMatch }
        isEmailExisting     = { isEmailExisting }
        handleRole          = { handleRole }
        allRoles            = { allRoles }
        handleChange        = { handleChange }
        isVisible           = { isModalNewAdminVisible }
        onSave              = { onSave }
        isLoading           = { isLoading }
        onClose             = { onClose }
        isDisabled          = { eventDetails.roleId == undefined || eventDetails.roleId == '' || eventDetails.email == '' || eventDetails.firstName == '' || eventDetails.LastName == '' || eventDetails.password == '' || eventDetails.confirmPassword == ''}
        />  

      <Box className={classes.box}>
        <div className={classes.label}>
          <Typography variant='h2' className={classes.labelText}>Admin</Typography>
          <Box className={classes.searchContainer}>
            <TextField 
              placeholder='Search'
              // onKeyDown={handlerKeyEnter}
              variant='outlined'
              value={inputValue}
              size='small'
              onChange={handlerSearchInput}
              className={classes.searchField}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.adornment} position='start' disablePointerEvents={true}>
                    <SearchIcon/>
                  </InputAdornment>
                )
              }}/>
            <PrimaryButton className={classes.addButton} onClick={() => toggleNewAdminModal(true)}>
              <AddIcon/>
              New Admin
            </PrimaryButton>
          </Box>
        </div>
        {
          adminUser?.length > 0 ?
            <div className  = { classes.table }>
              <DataGrid
                rows        = { adminUser }
                columns     = { ADMIN_TABLE_COLUMN  }
                getRowId    = { (row) => row?.userAdminId }
                disableColumnMenu
                disableSelectionOnClick
                components  ={{
                  Footer: () => <NextBeforeButtons
                                  TotalPage     = { totalPage }
                                  page          = { page }
                                  onPageBefore = { ()=> onPageBefore() }
                                  onPageNext = { ()=> onPageNext() }
                                  onNextTenPages = { ()=> onNextTenPages()}
                                  onPreviousTenPages = { ()=> onPreviousTenPages()}
                                  onSetPage = {onSetPage}
                                  />,
                }}/>
            </div> :
            <div className = { classes.table }>
               <DataGrid
                rows       = { adminUser }
                columns    = { ADMIN_TABLE_COLUMN }
                getRowId   = { (row) => row?.userAdminId }
                disableColumnMenu
                disableSelectionOnClick
                components={{
                  Footer: () => <NextBeforeButtons
                                  TotalPage     = { totalPage }
                                  page          = { page }
                                  onPageBefore = { ()=> onPageBefore() }
                                  onPageNext = { ()=> onPageNext() }
                                  onNextTenPages = { ()=> onNextTenPages()}
                                  onPreviousTenPages = { ()=> onPreviousTenPages()} />,
                }}/>
                <div className   = { classes.notFoundContainer }>
                  <div className = { classes.notFound }>
                  { isLoading ?
                    <CircularProgress color='primary' /> :

                    (adminUser?.length < 1) &&
                      <Empty
                        title        = { "No User" }
                        description  = { "Not Found" }
                        image        = { NO_USERS_FOUND }/>
                  }
                </div>
              </div>
          </div>
        }
      </Box>
    </div>
  );
};

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('admin/result/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user: state.User
  };
};

export default connect(mapStateToProps, { signOutUser })(
  Admin
)
