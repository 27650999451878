import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
 AppBar, Box, Toolbar, Typography, Popper, Grow, Paper, MenuList, MenuItem,
 ClickAwayListener, Drawer, IconButton,  List, ListItem, ListItemIcon, ListItemText, Avatar
} from '@material-ui/core';
 
import { AdminPanelSettings, Home, Person, People, MenuSharp, ChevronLeft, ChevronRight} from '@material-ui/icons'
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import useStyles from './style';
import sosLogo from '../../assets/images/logo-text-white.png'
import { FaUserLock } from "react-icons/fa";

import  rootAction from '../../redux/action';

import searchAction from '../../redux/search/action';
import userAction from '../../redux/user/action';

const { signOutUser } = rootAction;
const { getSearches } = searchAction;
const { getAdminPermission } = userAction;

const TopBar = (props) => {
  console.log('TopBar props: ', props);
  const classes   = useStyles();
  const anchorRef = useRef(null);
  const history = useHistory();

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [ profileImage, setProfileImage ] = useState('');
  const [ open, setOpen ]                 = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    const path = history.location.pathname;
    menus.map((menus, index) => {
      if (menus.link === path) {
        setSelectedIndex(index); 
        return;
      }
    })
    
    if(props.user.userAdminId){
      props.getAdminPermission({
        adminId: props.user.userAdminId
      })
    }
   
  }, [history.location.pathname])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  {/* Add Roles Handle*/}
  const goToMyProfile = (event) => {
    console.info('myProfile()');
    setOpen(false);
    setSelectedIndex(null)
    history.push("/profile");
  };

  const logout = () => {
    props.signOutUser();
  };

  const menus = [
    {id: 0, name: "Home", link: "/home", icon: <Home className={classes.listIcon}/>},
    {id: 1, name: "User", link: "/search", icon: <People className={classes.listIcon}/>},
    {id: 2, name: "Admin",  link: "/admin", icon: <Person className={classes.listIcon}/>},
    {id: 3, name: "Roles", link: "/roles", icon: <FaUserLock className={classes.listIcon}/>}
  ];


 const drawerClicked = (index) => {
    setSelectedIndex(index);
    history.push(menus[index].link);
 };
 
 const drawerHandler = () => {
  setIsDrawerOpen(!isDrawerOpen);
 };

  return (
    <div>
      <AppBar component={Paper} position='static' elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarTitle}>
            <img src={sosLogo} className={classes.logo} alt='S.O.Search'/>
          </div>
            <Box className={classes.menuButton}>
              <Avatar src={profileImage} style={{height:34 , width:34}}/>
              <IconButton
                color='secondary'
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                ref={anchorRef}
              >
                <KeyboardArrowDownOutlinedIcon style={{width: 35, height: 35}}/>
              </IconButton>
              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 1}}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: '100%' }}
                  >
                    <Paper className={classes.settingsContainer}>
                      <ClickAwayListener onClickAway={handleClose}>
                        {/* {settingsCollapsibleMenu()} */}
                          <MenuList>
                            <MenuItem onClick={goToMyProfile}> 
                              <Typography className={classes.menuItemProfile}>My Profile</Typography>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                              <Typography className={classes.menuItemLogout} >Logout</Typography>
                            </MenuItem>
                          </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
        </Toolbar>

      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,  
        })}
        classes={{
          paper: clsx(classes.drawer,{
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
      >
        <Toolbar />
          <Box  className={classes.closeMenu}  onClick={ () => drawerHandler() }>
            { isDrawerOpen ? <ChevronLeft style={{fontSize:20}} /> : <ChevronRight style={{fontSize:20}} /> }
          </Box> 
          <List> 
            {menus.map((menu) => (
              <ListItem key={menu.id} className={`${classes.listItem} ${selectedIndex == menu.id && classes.selectedItem}`} onClick={ () => drawerClicked(menu.id) }>
                <ListItemIcon className={classes.listIcon}>{menu.icon}</ListItemIcon>
                <ListItemText disableTypography
                primary={<Typography className= {classes.listText}>{menu.name}</Typography>}
                />
              </ListItem>
            ))}
          </List>
      </Drawer>       
   </div>
 );
};

const mapStateToProps = (state) => {
  console.log('topbar/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user: state.User
  };
};

export default connect(mapStateToProps, { signOutUser, searchAction, getAdminPermission })(TopBar);
