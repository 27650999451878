import React, { useState, useEffect } from 'react';

import {
  TextField,
  Tooltip,
  Button,
  Box,
  InputAdornment
} from '@material-ui/core';

import AdminPanelSettingsRounded from '@material-ui/icons/AdminPanelSettingsRounded';

import CModal    from '../../../components/c-modal';
import useStyles from './style';

import LoadingButton   from '../../../components/buttons/loading-button';
import SecondaryButton from '../../../components/buttons/secondary-button';
import PrimaryButton   from "../../../components/buttons/primary-button";
import { FILTER_SPECIAL_CHARACTERS } from '../../../utils/constants';

const UpdateRoleModal = (props) => {
  const classes = useStyles();

  let { isVisible, onClose, onSave, currentRole, handleOnChangeRoleName } = props;

  return (
    <CModal isVisible={isVisible} onClose={onClose} title={`Update '${currentRole}' Role`} onSave={onSave}>
      <Box className={classes.parentContainerCModal}>
        <Box className={classes.txtFieldContainer}>
          <Tooltip title={`Set a new Role name for ${currentRole}`}>
            <TextField
              className={classes.nameTxtField}
              size='small'
              id='outlined-basic'
              placeholder={currentRole}
              variant='outlined'
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AdminPanelSettingsRounded/>
                  </InputAdornment>
                )
              }}
              onChange={handleOnChangeRoleName}
            />
          </Tooltip>
        </Box>
      </Box>

      <Box className={classes.btnContainer}>
        <SecondaryButton onClick={onClose}>
          Cancel
        </SecondaryButton>

        <PrimaryButton onClick={onSave}>
          Save
        </PrimaryButton>
      </Box>

    </CModal>
  );
}

export default UpdateRoleModal;