import React                            from 'react';
import { sessionService }               from 'redux-react-session';
import ReactDOM                         from 'react-dom';
import {
  applyMiddleware,
  // compose,
  createStore
}                                       from 'redux'
import { Provider }                     from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage                          from 'redux-persist/lib/storage' // defaults to localStorage for web
import createSagaMiddleware             from 'redux-saga'
import { PersistGate }                  from 'redux-persist/integration/react'
import { composeWithDevTools }          from '@redux-devtools/extension';

import App                                       from './App';
import rootReducer                               from './redux/reducers.js'
import rootSaga                                  from './redux/sagas';
import * as serviceWorker                        from './serviceWorker';

import '../src/assets/index.css';

const sagaMiddleware = createSagaMiddleware();
const middlewares    = [sagaMiddleware];

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
  trace: true,
});

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
sessionService.initSessionService(store);


const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
