import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SnackBar from '../components/snackbar';

const PrivateRoute = (props) => {
	console.log('PrivateRoute props: ', props);
	console.log('PrivateRoute props user: ', props.user);
	const { authenticated, exact, component: Component, isUserEmpty, layout: Layout, path, isSignedIn, user } = props;

	const [open, setAlertOpen] = useState(false);
	const [message, setAlertMessage] = useState('');
	const [severity, setAlertSeverity] = useState('success');

	const showAlert = (severity, message) => {
		setAlertOpen(true);
		setAlertMessage(message);
		setAlertSeverity(severity)
	}

	const privateRouteProps = props;

	return (
		<Route
			exact	 = {exact}
			path	 = {path}
			render = {(props) =>
				(isSignedIn) ? (
					<Layout {...privateRouteProps}>
						<Component {...props} showAlert={showAlert} />
						<SnackBar open={open} setOpen={setAlertOpen} message={message} severity={severity} />
					</Layout>
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	)
};

export default PrivateRoute;