import action from "./action";

const initState = {
  searches        : [],
  searchResult    : [],
  search : {
    searchId: 1,
    searchName: "My Ideal",
    status: "ACT",
    searchDetail: {
      hairColor   : [],
      eyeColor    : [],
      rating      : [],
      heart       : [],
      bodyType    : [],
      kidPolicy   : [],
      educLevel   : [],
      ethnicity   : [],
      religBelief : [],
      exerRegimen : [],
      prefAlcohol : [],
      prefSmoking : [],
      posPolitics : [] 
    },
    users: []
  },
  isSearchLoading     : false,
  isSearchLoaded      : false,
  isSearchError       : false,
  isSearchAdding      : false,
  isSearchAdded       : false,
  isSearchAddError    : false,
  isSearchUpdating    : false,
  isSearchUpdated     : false,
  isSearchUpdateError : false,
  isSearchDeleting    : false,
  isSearchDeleted     : false,
  isSearchDeleteError : false,
};

export default function searchReducer(state = initState, act) {

  switch (act.type) {

    case action.SEARCH_GET_REQUEST:
      console.log("redux/search/reducer.js:SEARCH_GET_REQUEST");
      return {
        ...state,
        isSearchLoading : true
      }

    case action.SEARCH_GET_SUCCESS:
      console.log("redux/search/reducer.js:SEARCH_GET_SUCCESS");
      return {
        ...state,
        searches        : act.payload,
        isSearchLoading : false,
        isSearchLoaded  : true
      }

    case action.SEARCH_GET_ERROR:
      console.log("redux/search/reducer.js:SEARCH_GET_ERROR");
      return {
        ...state,
        isSearchLoading : false,
        isSearchError   : true
      }

    case action.SEARCH_SET_SEARCH:
      console.log("redux/search/reducer.js:SEARCH_SET_SEARCH");
      console.log(act.payload)
      return {
        ...state,
        search : act.payload
      }

    case action.SEARCH_ADD_REQUEST:
      console.log("redux/search/reducer.js:SEARCH_ADD_REQUEST");
      console.log(act.payload)
      return {
        ...state,
        isSearchAdding    : true,
        isSearchAdded     : false,
        isSearchUpdated   : false,
        isSearchDeleted   : false,
        isSearchAddError  : false
      }

    case action.SEARCH_ADD_SUCCESS:
      console.log("redux/search/reducer.js:SEARCH_ADD_SUCCESS");
      console.log(act.payload)
      return {
        ...state,
        isSearchAdding  : false,
        isSearchAdded   : true,
        search          : act.payload,
        searches        : [...state.searches, act.payload]
      }

    case action.SEARCH_ADD_ERROR:
      console.log("redux/search/reducer.js:SEARCH_ADD_ERROR");
      console.log(act.payload)
      return {
        ...state,
        isSearchAdding    : false,
        isSearchAddError  : true,
      }

    case action.SEARCH_UPDATE_REQUEST:
      console.log("redux/search/reducer.js:SEARCH_UPDATE_REQUEST");
      console.log(act.payload)
      return {
        ...state,
        isSearchUpdating    : true,
        isSearchAdded       : false,
        isSearchUpdated     : false,
        isSearchDeleted     : false,
        isSearchUpdateError : false,
      }

    case action.SEARCH_UPDATE_CRITERIA:
      console.log("redux/search/reducer.js:SEARCH_UPDATE_CRITERIA");
      console.log(act.payload)
      return {
        ...state,
        isSearchUpdating    : true,
        isSearchAdded       : false,
        isSearchUpdated     : false,
        isSearchDeleted     : false,
        isSearchUpdateError : false,
      }

    case action.SEARCH_UPDATE_SUCCESS:
      console.log("redux/search/reducer.js:SEARCH_UPDATE_SUCCESS");
      console.log(act.payload)
      return {
        ...state,
        search    : act.payload,
        searches  : state.searches.map(search => {
          let newSearch = search;
          if (search.searchId === act.payload.searchId) {
            newSearch = act.payload;
          }

          return newSearch;
        }),
        isSearchUpdating  : false,
        isSearchUpdated   : true,
      }

    case action.SEARCH_UPDATE_ERROR:
      console.log("redux/search/reducer.js:SEARCH_UPDATE_ERROR");
      console.log(act.payload)
      return {
        ...state,
        isSearchUpdating    : false,
        isSearchUpdateError : true
      }

    case action.SEARCH_DELETE_REQUEST:
      console.log("redux/search/reducer.js:SEARCH_DELETE_REQUEST");
      console.log(act.payload)
      return {
        ...state,
        isSearchDeleting    : true,
        isSearchAdded       : false,
        isSearchUpdated     : false,
        isSearchDeleted     : false,
        isSearchDeleteError : false
      }

    case action.SEARCH_DELETE_SUCCESS:
      console.log("redux/search/reducer.js:SEARCH_DELETE_SUCCESS");
      console.log(act.payload)
      return {
        ...state,
        isSearchDeleting  : false,
        isSearchDeleted   : true,
        searches          : state.searches.filter(search => search.searchId !== act.payload)
      }

    case action.SEARCH_DELETE_ERROR:
      console.log("redux/search/reducer.js:SEARCH_DELETE_ERROR");
      console.log(act.payload)
      return {
        ...state,
        isSearchDeleting    : false,
        isSearchDeleteError : true
      }

    default:
      return state
  }
}
