import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  TOTAL_USER,
  REGULAR_USER,
  PRIVATE_USER,
  ELITE_USER,
  MALE_USER,
  FEMALE_USER,
} from '../../utils/constants';

import Map from '../../components/map'

import TotalUserCard   from './total-user-card/index';
import MonthlyUser     from '../../components/monthlyuser/index'
// styles
import useStyles       from './style';
import useCommonStyles from '../../components/common/style';
import { userRequest } from '../../service/requests';


const Home = (props) => {
  const [ totalUsers, setTotalUsers ]                   = useState([]);
  const [ isTotalUsersLoading, setIsTotalUsersLoading ] = useState(false);


  const [ usersLocation, setUsersLocation ] = useState([]);

  const commonClasses = useCommonStyles();
  const classes = useStyles();
  
  useEffect(() => {
    (async function() {
      setIsTotalUsersLoading(true);
      const totalUsersRes = await userRequest.getTotalUsers().catch(error => {
        console.log('getResults error : ', error);
      }).finally(() => {
        setIsTotalUsersLoading(false)
      });
      setTotalUsers(totalUsersRes?.data?.totalUsers);
    }());
    
    (async function(){
      const location = await userRequest.getUsersLocation().catch(error => {
        console.log('getUsersLocation error : ', error);
      });
      setUsersLocation(location?.data?.userLocation);
    }());
  }, []);

  return (
    <div className={`${classes.container}`}>
      
       
       
       <div className={classes.userCards}>
        <TotalUserCard
          isLoading={isTotalUsersLoading}
          title={TOTAL_USER}
          totalUsers={totalUsers?.users}
          male={MALE_USER}
          totalMale={totalUsers?.maleUsers}
          female={FEMALE_USER}
          totalFemale={totalUsers?.femaleUsers}
        />

        <TotalUserCard
          isLoading={isTotalUsersLoading}
          title={REGULAR_USER}
          totalUsers={totalUsers?.regularMembers}
          male={MALE_USER}
          totalMale={totalUsers?.regularMale}
          female={FEMALE_USER}
          totalFemale={totalUsers?.regularFemale}
        />

        <TotalUserCard
          isLoading={isTotalUsersLoading}
          title={PRIVATE_USER}
          totalUsers={totalUsers?.privateMembers}
          male={MALE_USER}
          totalMale={totalUsers?.privateMale}
          female={FEMALE_USER}
          totalFemale={totalUsers?.privateFemale}
        />

        <TotalUserCard
          isLoading={isTotalUsersLoading}
          title={ELITE_USER}
          totalUsers={totalUsers?.eliteMembers}
          male={MALE_USER}
          totalMale={totalUsers?.eliteMale}
          female={FEMALE_USER}
          totalFemale={totalUsers?.eliteFemale}
        />
      </div>
      <Card className={classes.card} elevation={0}>
        <Map centerInMap={{lat: 34.052235, lng:-118.243683}} zoomInMap={5} heatmapData={usersLocation} />
      </Card>

        
{/*         
        <Grid item lg={4} md={4}>
          <MonthlyUser/>

        </Grid> */}
    </div>
 
  );
};

const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {};
};

export default connect(mapStateToProps)(Home);
