import React, { useEffect, useState }               from 'react';
import { Box, IconButton, Typography }              from '@material-ui/core';
import { NavigateBefore, NavigateNext, DoubleArrow} from '@material-ui/icons';
import useStyles                                    from './style';

function NextBeforeButtons (props){
  
    const { page, TotalPage = 1, onSetPage = ()=>{}, isLoading = false } = props;
    const classes       = useStyles();

    const pages = [1]
    let firstPages = []
    let middlePages = []
    let lastPages = []

    firstPages = [1,2,3,4,5,6,7,8,9,null]
    lastPages = [TotalPage, TotalPage - 1, TotalPage - 2]
    for (let x = 2; x <= TotalPage; x++) {
      pages.push(x)
    }

    if(page > 8) {
      firstPages = [1,2,3,null]
      for(let x = page-2; x <= page + 2; x++){
        middlePages.push(x)
      }
      middlePages.push(null)
    }

    if(page > (TotalPage - 6  )){
      middlePages = []
      lastPages = []
      for (let x = TotalPage, y = 0; x >= 1 ; x--, y++) {
        if(y == 9){
          break
        }
        lastPages.push(x)
      }
    }    

    const newPages = pages.length <= 20 ? pages : [...firstPages, ...middlePages, ...lastPages.reverse()]
    
    return(
        <Box className={ classes.box } >
          <IconButton 
            style={{transform: 'rotate(180deg)' }}
            className={classes.iconBox} 
            disabled = { page <= 10 ? true : false } 
            onClick={ ()=> {
              !isLoading && props.onPreviousTenPages()
            } }>
              <DoubleArrow style={{fontSize:'15px'}} />
          </IconButton>

          <IconButton 
            className={classes.iconBox} 
            disabled = { page == 1 ? true : false } 
            onClick={ ()=> {
              !isLoading && props.onPageBefore()
            }}>
              <NavigateBefore style={{fontSize:'20px'}}/>
          </IconButton>
          
          <Box component={'div'} className={classes.pages}>
            {newPages.map((pageNum, index) => {
              // console.log(pageNum == (index + 1) )
              return (
                <Box 
                  className={(page != pageNum && pageNum != null) ? [classes.page, classes.pointer] : pageNum == null ? classes.separator : classes.currentPage }
                  onClick={()=>{
                    (!isLoading && pageNum != null) && onSetPage(pageNum)
                  }}
                  key={index}
                >
                  <Typography> {`${pageNum != null ? pageNum : '...' }`} </Typography>
                </Box>
              );
            })}
          </Box>

          <IconButton 
            className={classes.iconBox} 
            disabled = { page == TotalPage ? true : false } 
            onClick={ ()=> {
              !isLoading && props.onPageNext()
            } }>
              <NavigateNext style={{fontSize:'20px'}} />
          </IconButton>

          <IconButton 
            className={classes.iconBox} 
            disabled = { page >= TotalPage - 10 ? true : false } 
            onClick={ ()=> {
              !isLoading && props.onNextTenPages()
            } }>
              <DoubleArrow style={{fontSize:'15px'}}/>
          </IconButton>
      </Box>
    )
}
export default NextBeforeButtons;