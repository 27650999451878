import React, { useState, useEffect }   from 'react';
import {  IconButton,
          TextField,
          InputAdornment,
          Box,
          Divider,
          Typography } from '@material-ui/core';

import useStyles from './style';
import useCommonClasses from '../../components/common/style'
import LoadingButton from '../../components/buttons/loading-button';
import SecondaryButton from "../../components/buttons/secondary-button";
import { Visibility, VisibilityOff } from '@material-ui/icons';

import userAction from '../../redux/user/action';
import { connect } from 'react-redux';
import { userAdminRequest } from '../../service/requests';

import { showSuccessAlert } from '../../utils/alerts';
import {  FILTER_SPECIAL_CHARACTERS, 
          TOAST_UPDATE_SUCCESSFULLY, 
          FILTER_SPECIAL_CHARACTERS_EMAIL,   
          TOAST_CHANGE_EMAIL_SUCCESS} from '../../utils/constants';

const { updatePersonalInfo } = userAction;

const MyProfile = (props) => {
  
  const { user, updatePersonalInfo } = props;
  const classes = useStyles();
  const commonClasses = useCommonClasses();
  const [ isUpdating, setIsUpdating ] = useState(false);

  //Change Personal Info Variables
  const [ firstName, setFirstName]                          = useState(user?.firstName);
  const [ lastName, setLastName]                            = useState(user?.firstName);
  const [ emailAddress, setEmailAddress ]                   = useState(user?.email);
  const [ isEditingInfo, setIsEditingInfo ]                 = useState(false);
  const [ isEmailExisting, setIsEmailExisting ]             = useState(false);

  //Change Password Variables
  const [ isChangingPassword, setIsChangingPassword ]       = useState(false);
  const [ isOldPasswordVisible, setIsOldPasswordVisible ]   = useState(false);
  const [ isOldPasswordNotMatch, setIsOldPasswordNotMatch ] = useState(false);
  const [ oldPassword, setOldPassword ]                     = useState('');
  const [state, setState] = useState({
    isPasswordVisible        : false,
    isConfirmPasswordVisible : false,
    password                 : '',
    isPasswordError          : false,
    passwordErrorDesc        : '',
    confirmPassword          : '',
    isConfirmPasswordError   : false,
    confirmPasswordErrorDesc : '',
    isPasswordPassed         : false, 
    passwordCriteria         :  {
                                  has8Characters           : false,
                                  has1Lowercase            : false,
                                  has1Uppercase            : false,
                                  has1Number               : false,
                                  has1SpecialCharacter     : false
                                }
  });
  const { 
    password, confirmPassword, isPasswordVisible,
    isPasswordError, passwordErrorDesc, isConfirmPasswordVisible,
    isConfirmPasswordError, confirmPasswordErrorDesc, 
    passwordCriteria, isPasswordPassed
  } = state;

  useEffect(() => {
    if (password !== confirmPassword) {
      setState({
        ...state,
        isConfirmPasswordError: true,
        confirmPasswordErrorDesc: 'Password mismatched.'
      });
    } 
      else {
      setState({
        ...state,
        isConfirmPasswordError: false,
        confirmPasswordErrorDesc: ''
      });
    }
  }, [password, confirmPassword])

  useEffect(()=>{
    if (password != '' && oldPassword == password){
      setState({
        ...state,
        isPasswordError: true,
        passwordErrorDesc: 'New password must be different from current password.'
      });
    } 
    else {
      setState({
        ...state,
        isPasswordError: false,
        passwordErrorDesc: ''
      });
    }
  }, [oldPassword])

  const handleInputChange = (event) => {

    const inputValue = event.target.value;
    
    let newState = {
      ...state,
      [event.target.name]: inputValue,
    }

    if (event.target.name === 'password') {
      if(inputValue == oldPassword){
        newState = {
          ...newState,
          isPasswordError: true,
          passwordErrorDesc: 'New password must be different from current password.'
        }
      } else {
        const has8Characters       = inputValue.length >= 8;
        const has1Lowercase        = /[a-z]/.test(inputValue);
        const has1Uppercase        = /[A-Z]/.test(inputValue);
        const has1Number           = /[0-9]/.test(inputValue);
        const has1SpecialCharacter = /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(inputValue);

        newState = {
          ...newState,
          isPasswordPassed : has8Characters && has1Lowercase && has1Uppercase && has1Number && has1SpecialCharacter,
          isPasswordError   : !has8Characters || !has1Lowercase || !has1Uppercase || !has1Number || !has1SpecialCharacter,
          passwordErrorDesc : !has8Characters ? 'Password must contain more than 8 characters' : !has1Lowercase ? 'Password must have 1 lowercase letter' : !has1Uppercase ? 'Password must have 1 uppercase letter' 
                              : !has1Number ? 'Password must contain at least one number ' : !has1SpecialCharacter ? 'Password must contain at least one special characters' : '',
          passwordCriteria : {
            has8Characters,
            has1Lowercase,
            has1Uppercase,
            has1Number,
            has1SpecialCharacter }
        }
      }
    }
    setState({
      ...newState
    });
  }

  const changePassword = async () => {
    setIsUpdating(true)
    const changePasswordResponse = await userAdminRequest.changePassword({
      user_admin_id : user?.userAdminId,
      old_password  : oldPassword,
      password      : password
    }).catch(error => {
      console.log('changePasswordResponse error : ', error);
      });

      
    if(changePasswordResponse.data?.code == 200){
      console.log('Success')
      cancelBtn()
      showSuccessAlert(TOAST_CHANGE_EMAIL_SUCCESS);
    }else{
      setIsOldPasswordNotMatch(true)
    }
    setIsUpdating(false)
  }

  const updateInfo = async () => {

    setIsUpdating(true);
    if(firstName != user?.firstName || lastName != user?.lastName){
      const nameUpdateResponse = await userAdminRequest.updatePersonalInformation({
        user_admin_id: user?.userAdminId,
        first_name: firstName,
        last_name: lastName,
      }).catch(error => { console.log('changeName error : ', error) });
        
      console.log('update personalInfo response: ', nameUpdateResponse);

      if(nameUpdateResponse.status == 200) {
        console.log('Updated personalInfo successfully');
        updatePersonalInfo('firstName',firstName);
        updatePersonalInfo('lastName',lastName);
        showSuccessAlert(TOAST_UPDATE_SUCCESSFULLY);
        setIsEditingInfo(false);
      } else {
        console.log('error', nameUpdateResponse);
      }
    }

    if(emailAddress != user?.email) {
      const updateEmailResponse = await userAdminRequest.changeEmail({
        user_admin_id : user?.userAdminId,
        email         : emailAddress
      }).catch(error => {  console.log('changeEmail error : ', error) });
        
        console.log('changeEmail response : ', updateEmailResponse);
      if(updateEmailResponse.data?.status?.code == 200) {
        console.log('Update Email Success')
        updatePersonalInfo('email',emailAddress)
        showSuccessAlert(TOAST_UPDATE_SUCCESSFULLY);
        setIsEditingInfo(false);
      }else{
        setIsEmailExisting(true)
      }
    }
    setIsUpdating(false);
  }

  const cancelBtn = () => {
    if(isChangingPassword) {
      setState({
        isPasswordVisible        : false,
        isConfirmPasswordVisible : false,
        password                 : '',
        isPasswordError          : false,
        passwordErrorDesc        : '',
        confirmPassword          : '',
        isConfirmPasswordError   : false,
        confirmPasswordErrorDesc : '',
        isPasswordPassed         : false, 
        passwordCriteria         : {
                                    has8Characters           : false,
                                    has1Lowercase            : false,
                                    has1Uppercase              : false,
                                    has1Number               : false,
                                    has1SpecialCharacter     : false
                                  }})
      setIsChangingPassword(false)
      setIsOldPasswordNotMatch(false)
      setIsOldPasswordVisible(false)
      setOldPassword('')
    } else {
      setIsEditingInfo(false);
      setIsEmailExisting(false);
      setIsChangingPassword(false);
      setEmailAddress(user?.email);
      setLastName(user?.firstName);
      setFirstName(user?.lastName);
    };
  }

  return (
    <div className={commonClasses.root}>
      <Box className={classes.box}>

        <Typography className={classes.textLabel}>Personal Information</Typography>
        <Divider style={{marginBottom: '20px'}}/>
        <Box className = { classes.textInputBox }> 

          <Box className={classes.personalInfo}>

            <Typography className={classes.inputLabel}>First Name</Typography>
            <TextField
              required
              name        = 'firstName'
              value       ={ firstName }
              disabled    = { !isEditingInfo }
              className   = { classes.textInput }
              size        = 'small'
              id          = 'outlined-basic'
              variant     = 'outlined'
              onChange    = {(event) => { setFirstName(event.target.value.replace(FILTER_SPECIAL_CHARACTERS, '')) }}/>
        
          </Box>
          <Box className={classes.personalInfo}>

            <Typography className={classes.inputLabel}>Last Name</Typography>
            <TextField
              required
              name        = 'LastName'
              value       = { lastName }
              disabled    = { !isEditingInfo }
              className   = { classes.textInput }
              size        = 'small'
              variant     = 'outlined'
              onChange    = { (event) => { setLastName(event.target.value.replace(FILTER_SPECIAL_CHARACTERS, '')) }} />
  
          </Box>

        </Box>

        <Divider  style={{marginTop: '100px'}}/>
        <Typography className={classes.textLabel}>Account Information</Typography>
        <Divider style={{marginBottom: '20px'}}/>
        <Box className = { classes.textInputBox }> 
        
          <Box className={classes.personalInfo}>
            <Typography className={classes.inputLabel}>Email Address</Typography>
            <TextField
              required
              type        = {'email'}
              value       = { emailAddress }
              disabled    = { !isEditingInfo }
              error       = { isEmailExisting }
              helperText  = { isEmailExisting ? "Email Already Exists." : "" }
              name        = 'email'
              className   = { classes.emailInput }
              size        = 'small'
              id          = 'standard-basic'
              variant     ='outlined'
              onChange    = {(event) => { 
                setEmailAddress(event.target.value.replace(FILTER_SPECIAL_CHARACTERS_EMAIL, ''))
                setIsEmailExisting(false)
                }}/>
          </Box>

        </Box>
        <Box paddingLeft={'30px'} className={classes.personalInfo}>

          <Typography className={classes.inputLabel}>Current Password</Typography>
          <TextField
            type       = { isOldPasswordVisible ? 'text' : 'password' }
            value      = { oldPassword }
            error      = { isOldPasswordNotMatch }
            helperText = { isOldPasswordNotMatch ? 'Old Password does not match.' : '' }
            variant    = 'outlined'
            size       = 'small'
            disabled   = { !isChangingPassword }
            className  = { classes.textInput }
            onChange   = { (event)=> { 
              setIsOldPasswordNotMatch(false)
              setOldPassword(event.target.value) } }
            InputProps = {{
                          endAdornment : (
                            <InputAdornment position='end'>
                              <IconButton
                                disabled    = { !isChangingPassword }
                                aria-label  = 'Toggle password visibility'
                                onClick     = { ()=> setIsOldPasswordVisible(prev => !prev) }
                                onMouseDown = { ()=> setIsOldPasswordVisible(prev => !prev) }
                                edge        = 'end'>
                                  { isOldPasswordVisible ? <Visibility/> : <VisibilityOff/> }
                              </IconButton>
                            </InputAdornment>)}} />
        </Box>
        <Box className = { classes.textInputBox }> 
          <Box className ={classes.personalInfo}>

            <Typography className={classes.inputLabel}>New Password</Typography>
            <TextField
              name        = { 'password' }
              type        = { isPasswordVisible ? 'text' : 'password'}
              value       = { password }
              error       = { isPasswordError }
              helperText  = { passwordErrorDesc }
              variant     = 'outlined'
              size        = 'small'
              disabled    = { !isChangingPassword }
              className   = { classes.textInput }
              onChange    = { handleInputChange }
              InputProps  = {{
                              endAdornment : (
                                <InputAdornment position = 'end'>
                                  <IconButton
                                    disabled    = { !isChangingPassword }
                                    aria-label  = 'Toggle password visibility'
                                    onClick     = { () => { setState({ ...state, isPasswordVisible: !isPasswordVisible }) }}
                                    onMouseDown = { (event) => { event.preventDefault() } }
                                    edge        ='end' >
                                      { isPasswordVisible ? <Visibility /> : <VisibilityOff /> }
                                  </IconButton>
                                </InputAdornment>)}} />
        
          </Box>
          <Box className={classes.personalInfo}>

            <Typography className={classes.inputLabel}>Confirm New Password</Typography>
            <TextField
              type          = { isConfirmPasswordVisible ? 'text' : 'password' }
              value         = { confirmPassword } 
              name          = { 'confirmPassword' }
              error         = { isConfirmPasswordError }
              helperText    = { confirmPasswordErrorDesc }
              variant       = 'outlined'
              size          = 'small'
              disabled      = { !isChangingPassword}
              className     = { classes.textInput }
              onChange      = { handleInputChange }
              InputProps    = {{
                                endAdornment  : (
                                  <InputAdornment position = 'end'>
                                    <IconButton
                                      disabled    = { !isChangingPassword }
                                      aria-label  = 'Toggle password visibility'
                                      onClick     = { () => { setState({ ...state, isConfirmPasswordVisible: !isConfirmPasswordVisible }) } }
                                      onMouseDown = { (event) => { event.preventDefault() } }
                                      edge        = 'end'>
                                        { isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff /> }
                                    </IconButton>
                                  </InputAdornment>)}} /> 
          </Box>
        </Box>
        <Divider style={{margin: '60px 0px 20px 0px'}}/>

        <Box className  = { classes.btnContainer }>
          {
            (isChangingPassword || isEditingInfo) &&
            <SecondaryButton onClick  = { cancelBtn } className= {classes.cancelBtn}>
            <Typography>{`Cancel`}</Typography>
            </SecondaryButton>
          }
          {
            !isEditingInfo &&
          <LoadingButton
            disabled  = { (isConfirmPasswordError || isPasswordError || password == '' || confirmPassword == '' || isOldPasswordNotMatch) && isChangingPassword }
            type      = 'submit'
            onClick   ={ () => {isChangingPassword ?  changePassword() : setIsChangingPassword(true)} }
            isLoading = { isUpdating }
            className = { classes.changePassBtn }>
              {
                <Typography>{ isChangingPassword ? 'Save' : 'Change Password'}</Typography>
              }    
          </LoadingButton>
          }
          {
            !isChangingPassword &&
            <LoadingButton
              disabled  = {( firstName == '' || lastName == '' || (isEditingInfo && firstName == user?.firstName && lastName == user?.lastName)) && ((isEditingInfo && emailAddress == user?.email ) || emailAddress == '' || isEmailExisting)}
              type      = 'submit'
              onClick   ={ () => { isEditingInfo ? updateInfo() : setIsEditingInfo(true) } }
              isLoading = { isUpdating }
              className = { classes.profileInfoBtn }>
                {
                  <Typography>{ isEditingInfo ? 'Save' : 'Edit'}</Typography> 
                }    
            </LoadingButton>
          }
        </Box> 

      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('views/my-profile/index.js:mapStateToProps(state):', state.User.user);
  return {
    user : state.User,
  };
};

export default connect( mapStateToProps, { updatePersonalInfo })(
  MyProfile
);