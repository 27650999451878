import React               from 'react';
import { Typography }      from '@material-ui/core/'
import useStyles           from './style';
import Card                from '@material-ui/core/Card';
import CardContent         from '@material-ui/core/CardContent';
import { getRandomNumber } from "../../../utils/helper";
import { Box }             from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';

const TotalUserCard = ({isLoading, totalUsers, totalMale, totalFemale, title, male,  female}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Card className={`${classes.card} preloader`} elevation={0}>
        <CardContent className={classes.content}>
          <Typography className={`${classes.title}`}>
            <Skeleton variant="rect" width={150}  />
          </Typography>
          <Typography className={`${classes.subTitle}`}>
            <Skeleton variant="rect" width={80} style={{marginTop: 10}} />
          </Typography>
          <ul className={classes.grid}>
            <li>
              <Typography className={`${classes.listTitle}`}>
                <Skeleton variant="rect" width={100}  />
              </Typography>
              <Typography className={`${classes.listContent}`}>
                <Skeleton variant="rect" width={50}  />
              </Typography>
            </li>
            <li className={classes.margin}>
              <Typography className={`${classes.listTitle}`}>
                <Skeleton variant="rect" width={100}  />
              </Typography>
              <Typography className={`${classes.listContent}`}>
                <Skeleton variant="rect" width={50}  />
            </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    )
    // return (
    //   <Card className={[classes.card, 'preloader']} >
    //     <CardContent >
    //
    //       <Typography className={classes.OrangeText}>
    //         {title}
    //       </Typography>
    //       <Typography className={classes.BlackText}>
    //         <div className={classes.preLoaderText} style={{ width: '90%'}} />
    //       </Typography>
    //       <Typography className={classes.OrangeText}>
    //         {male}
    //       </Typography>
    //       <Typography className={classes.BlackText}>
    //         <div className={classes.preLoaderText} style={{ width: '90%'}} />
    //       </Typography>
    //       <Typography className={classes.OrangeText}>
    //         {female}
    //       </Typography>
    //       <Typography className={classes.BlackText}>
    //         <div className={classes.preLoaderText} style={{ width: '90%'}} />
    //       </Typography>
    //     </CardContent>
    //   </Card>
    // )
  } else {
    return (
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.content}>
          <Typography className={`${classes.title}`}>
            {title}
          </Typography>
          <Typography className={`${classes.subTitle}`}>
            {totalUsers}
          </Typography>
          <ul className={classes.grid}>
            <li>
              <Typography className={`${classes.listTitle}`}>
                {male}
              </Typography>
              <Typography className={`${classes.listContent}`}>
                {totalMale}
              </Typography>
            </li>
            <li className={classes.margin}>
              <Typography className={`${classes.listTitle}`}>
                {female}
              </Typography>
              <Typography className={`${classes.listContent}`}>
                {totalFemale}
            </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    )
  }


};

export default TotalUserCard;