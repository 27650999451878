import React, { useState, useEffect } from 'react';

import {
  TextField,
  Tooltip,
  Button,
  Box,
  InputAdornment
} from '@material-ui/core';

import AdminPanelSettingsRounded from '@material-ui/icons/AdminPanelSettingsRounded';

import CModal    from '../../../components/c-modal';
import useStyles from './style';

import LoadingButton   from '../../../components/buttons/loading-button';
import SecondaryButton from '../../../components/buttons/secondary-button';
import PrimaryButton   from "../../../components/buttons/primary-button";
import { FILTER_SPECIAL_CHARACTERS } from '../../../utils/constants';

const NewRoleModal = (props) => {
  const classes = useStyles();

  const { isVisible, onClose, onSave, roleName, setRoleName } = props;

  const handleOnChangeRoleName = (event) => {
    let tempRoleName = event.target.value.replace(FILTER_SPECIAL_CHARACTERS, '');
    setRoleName(tempRoleName);
  };

  return (
    <CModal isVisible={isVisible} title='Create a new Role' onClose={onClose} onSave={onSave}>
      <Box className={classes.containerCModal}>
        <Box className={classes.txtFieldContainer}>
          <Tooltip title=''>
            <TextField
              className={classes.nameTxtField}
              size='small'
              id='outlined-basic'
              placeholder='Role Name'
              value={roleName}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AdminPanelSettingsRounded/>
                  </InputAdornment>
                )
              }}
              onChange={ (event) => { handleOnChangeRoleName(event) } }
            />
          </Tooltip>
        </Box>
      </Box>
      <Box className={classes.btnContainer}>
        <SecondaryButton
          onClick={onClose}
          className={classes.cancelBtn}>
            Cancel
        </SecondaryButton>

        <PrimaryButton className={classes.saveBtn} onClick={onSave}>
          Save
        </PrimaryButton> 

        {/*<LoadingButton*/}
        {/*  className={classes.saveBtn}*/}
        {/*  text='SAVE'*/}
        {/*  onClick={onSave}/>*/}
        {/*<LoadingButton*/}
        {/*  className={classes.saveBtn}*/}
        {/*  text='SAVE'*/}
        {/*  isLoading={true}*/}
        {/*  onClick={onSave}/>*/}
      </Box>
    </CModal>
  );
}

export default NewRoleModal;