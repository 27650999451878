import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

export const useStyles = makeStyles(theme => ({
  // ADMIN
  root: {
    position                 : 'absolute',
    marginTop                : '120px',
    marginLeft               : 'auto',
    marginRight              : 'auto',
    left                     : 0,
    right                    : 0,
    [theme.breakpoints.down("sm")]: {
      marginTop              : '165px'
    },

    '& > .MuiGrid-root': {
      marginTop              : theme.spacing(4),
    },
  },

  containerCModal:{
    display                  : 'flex',
    flex                     : 1,
    justifyContent           : 'center',
    flexDirection            : 'column',
    padding                  : '10px 25px 25px 25px'
  },

  flexEnd: {
    alignItems               : 'flex-end',
    display                  : 'flex',
    justifyContent           : 'flex-end',
  },

  header: {
    borderBottom             : `1px solid ${colors.TEXT_GRAY}`,
    display                  : 'flex',
    flexDirection            : 'row',
    justifyContent           : 'space-between',
    padding                  : theme.spacing(3, 0, 1, 0),
  },

  headerLeft: {
    flex                      : 1,
    fontWeight                : 'bold',
    padding                   : theme.spacing(0, 0, 0, 4),
  },

  headerRight: {
    flex                      : 1,
    display                   : 'flex',
    fontWeight                : 'bold',
    padding                   : theme.spacing(0, 4, 0, 0),
    justifyContent            : 'flex-end'
  },

  paper: {
    backgroundColor: colors.BACKGROUND_COLOR,
    marginTop                 : theme.spacing(4),
  },

  searchContainer: {
    marginTop                 : theme.spacing(2),
    display                   : 'flex',
    flexDirection             : 'column'
  },

  searchFieldContainer: {
    alignItems                : 'flex-end',
    display                   : 'flex',
    justifyContent            : 'flex-end',
    margin                    : theme.spacing(2, 0, 2, 0),
  },

  notFound: {
    width                     : '100%',

  },

  defaultSearch: {
    fontWeight                : 'bold',
  },

  searchCount: {
    display                   : 'inline-flex'
  },

  action: {
    flex                      : 1,
    minWidth                  : 80,
    display                   : 'flex',
    justifyContent            : 'flex-end'
  },

  actionButtonEmpty: {
    height                    : 24,
    minHeight                 : 24,
    width                     : 24,
    minWidth                  : 24,
    margin                    : theme.spacing(0, 0, 0, 1),
  },

  actionButton: {
    backgroundColor : 'transparent',
    // borderRadius: 32/2,
    // height: 32,
    // minHeight: 32,
    // width: 32,
    // minWidth: 32,
    padding                   : 0,
    margin                    : theme.spacing(0, 0, 0, 1),
    zIndex                    : 2,

    '&:hover': {
      backgroundColor         : colors.HOVER_DEFAULT
    },
  },

  // MESSAGE CENTER CARD

  notifCard: {
    alignItems                : 'center',
    backgroundColor           : colors.WHITE,
    display                   : 'flex',
    padding                   : 5,
    position                  : 'relative'
  },

  image: {

    margin          : theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height                  : 50,
      width                   : 50,
      borderRadius            : 50/2,
    },
    [theme.breakpoints.up('sm')]: {
      height                  : 75,
      width                   : 75,
      borderRadius            : 75/2,
    },
    [theme.breakpoints.up('md')]: {
      height                  : 75,
      width                   : 75,
      borderRadius            : 75/2,
    },
  },

  contentView: {
    flex                      : '1',
    alignSelf                 : 'center',
  },

  info: {
    display                   : 'flex',
    flexDirection             : 'row',
    alignItems                : 'center',
  },


  // Search Result
  searchResult: {
    display                   : 'flex',
    justifyContent            : 'space-between',
    alignItems                : 'center'
  },
  searchField:{

    backgroundColor           : colors.WHITE,
    width                     : '45%',
    margin                    : 50,

    "@media only screen and (max-width: 768px)": {
      width: '100%',
    }
  },
  box: {
    display                   : 'flex',
    flexDirection             : 'column',
    justifyContent            : 'center',
    alignItems                : 'center',
  },

  SeachButtonbox: {
    display                   : 'flex',
    flexDirection             : 'row',
    justifyContent            : 'center',
    alignItems                : 'center',
  },

  searchButton:{
    backgroundColor           : colors.PRIMARY,
    color                     : colors.WHITE,
    textTransform             : 'none',
  },
  clearButton:{
    backgroundColor           : colors.TRANSPARENT,
    color                     : colors.TEXT_GRAY,
    textTransform             : 'none',
  },
  paginationButtonContainer:{
    display                   : 'flex',
    justifyContent            : 'flex-end'
  },
  Buttonbox: {
    display                   : 'block',
    position                  : 'absolute',
    bottom                    : '87.3%',
    left                      : '73%'
  },
  table:{
    height                    : 600,
    width                     : '100%',
    backgroundColor           : colors.WHITE,
    textAlign                 : 'center'
  },

  cancelBtn: {
    color                     : colors.TEXT_BLACK,
    fontSize                  : '16px',
    marginRight               : '10px',
    height                    : '50px',
    cursor                    : 'pointer',
  },

  saveBtn: {
    fontSize                  : '16px',
    width                     : '115px',
    height                    : '50px'
  },

  btnContainer: {
    display                   : 'flex',
    justifyContent            : 'flex-end',
    margin                   : '20px 30px 20px 0px',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor         : 'rgba(244, 127, 52, 0.4)',
      color                   : colors.WHITE
    }
  },

  textInputBox:{
    display                   : 'flex',
    flexDirection             : 'row',
    justifyContent            : 'flex-start',

    "@media only screen and (max-width: 768px)": {
      width                   : '100%',
      flexDirection           : 'column',
    }
  },

  textInput:{
    width                      : '315px',
    height                     : '50px',
    marginLeft                 : '10px',
    marginRight                : '10px',
  },

  emailInput:{
    width                     : '650px',
    height                    : '50px',
    margin                    : 10,
  },

  personalInfo: {
    display                   : 'flex',
    flexDirection             : 'column'
  },

  inputLabel: {
    paddingTop                : '10px',
    paddingLeft               : '10px',
    fontSize                  : '16px',
    fontWeight                : 900
  },

  //// Reusable modal styling ///
  titleHeadingsModal:{
    backgroundColor          : 'transparent',
    color                    : '#B5B5B5',
    fontSize                 : '16px',
    fontWeight               : 'bold',
    textAlign                : 'left',
    marginLeft               : '10px',
    marginTop                : '15px'
  },

  multiSelectBox: {
    paddingTop               : '10px',
    paddingRight             : '8px',
    marginLeft               : '10px',
    width                    : '660px',
  },

  visibleIcon: {
    position                 : 'absolute',
    left                     : '84%'
  },

}));

//Select Style using KEY
export const selectStyle = {
 
  valueContainer    : () => ({
    color           : colors.PRIMARY
  }),
  dropdownIndicator : () => ({
    color           : colors.PRIMARY,
    'svg'           : {
      height        : '27px',
      width         : '27px'
    }
  }),
  indicatorSeparator: () => ({
    display         : null
  }),
  control: (base, state) => ({
    ...base,
        border        : state.isFocused ? `1px solid ${colors.PRIMARY}` : `1px solid ${colors.LIGHT_GRAY}`,
        padding       : 8,
        boxShadow     : state.isFocused ? 0 : 0,
        "&:hover"     : {
          border      : state.isFocused ? `1px solid ${colors.PRIMARY}` : `1px solid ${colors.GRAY}`
        },
    })
}