
import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, Tab, Tabs
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import useStyles from './style';
import action from '../../../redux/user/action';
const { getAdminPermission } = action;

const navigationTabs = [
  {
    key: 0,
    label: 'Home',
    link: "/home"
  },
  {
    key: 1,
    label: 'Search',
    link: "/search"
  },
  {
    key: 2,
    label: 'Admin',
    link: "/admin"
  },
  {
    key: 3,
    label: 'Roles',
    link: "/roles"
  }
]

const NavigationBar = (props) => {

  const { getAdminPermission } = props

  const classes = useStyles();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }


  useEffect(() => {
    const path = history.location.pathname;
    navigationTabs.map((tabs, index) => {
      if (tabs.link === path) {
        setSelectedIndex(index);
        return;
      }
    })
    
    if(props.user.userAdminId){
      getAdminPermission({
        adminId: props.user.userAdminId
      })
    }
   
  }, [history.location.pathname])

  return (
    <Tabs
      value={selectedIndex}
      indicatorColor='secondary'
      textColor='secondary'
      scrollButtons='on'
      aria-label='main tabs'
      className={classes.tabsContainer}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    >
      {navigationTabs.map((tab, index) => {
        return <Tab
          key={tab.key}
          label={tab.label}
          {...a11yProps(index)}
          value={index}
          className={selectedIndex == tab.key ? classes.selectedTab : classes.tab}
          onClick={() => {
            history.push(tab.link);
            setSelectedIndex(tab.key);
          }} />
      })}
    </Tabs>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state.User
  };
};
export default connect(mapStateToProps, { getAdminPermission })(
  NavigationBar
)