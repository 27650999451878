import moment from 'moment';

import { TOAST } from './constants';
import { showFailedAlert } from './alerts';

export const handleHttpError = (error, request) => {
  showFailedAlert(TOAST.REQUEST_ERROR);
  throw `${request} error: ${error.message}`
};

/////////////////////////////
// disableLogs()
/////////////////////////////
export const disableLogs = () => {
  if (process?.env?.NODE_ENV !== 'development') {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
}

/////////////////////////////
// getAddress()
/////////////////////////////
export const getAddress = (address) => { 

  const { city, state, country } = address;

  const fullAddress = [];
  const zipCode     = (address.zipCode !== null && address.zipCode !== '') ? address.zipCode : '';
  
  city 		!== null && city 		!== '' && fullAddress.push(city);
  state 	!== null && state 	!== '' && fullAddress.push(state);
  country !== null && country !== '' && fullAddress.push(country);

  return fullAddress.length > 0 ? `${fullAddress.join(', ')} ${zipCode}` : '-';
}

/////////////////////////////
// getAge()
/////////////////////////////
export const getAge = (birthDate) => {
  const today           = moment();
  const monthDifference = today.format('MM') - moment(birthDate).month();
  let   age             = today.format('YYYY') - moment(birthDate).year();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }

  return age;
}

/////////////////////////////
// getDistance()
/////////////////////////////
export const getDistance = (distance) => {

  const meter = Math.round(distance);
  
  return (meter > 1) ? meter + " meters" : meter + " meter";
}



/////////////////////////////
// getLookUpDesc()
/////////////////////////////
export const getLookUpDesc = (lookUp, code) => {

  if (lookUp.length === 0)
    return 'Not Stated'

  let description;
  if (Array.isArray(code)) {
    description = code.map(value => lookUp.find(item => item.code == value).description).toString().replace(',', ', ');
  } else {
    description = lookUp.find(item => item.code == code) ? lookUp.find(item => item.code == code).description : 'Not Stated';
  }

  return description;
}

/////////////////////////////
// createRandomCharacters()
/////////////////////////////
export const createRandomCharacters = (length) => {

  let result             = '';
  const characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/////////////////////////////
// getFormattedDateTime()
/////////////////////////////
export const getFormattedDateTime = (paramDateTime, paramRawFormat, paramFormat) => {
  
  let dateTime = paramDateTime;

  if (paramRawFormat === 'unix') {
    dateTime = moment.unix(dateTime);
    
    // moment
  }
  
  return moment(dateTime).format(paramFormat);
}

/////////////////////////////
// formatDateTime()
/////////////////////////////
export const formatDateTime = (unix) => {
  
  // Old Implementation
  // return moment.unix(unix).calendar(null, {
  //   sameDay : '[Today at] h:mm A',
  //   lastDay : 'dddd MMMM D, YYYY [at] h:mm A',
  //   lastWeek : 'dddd MMMM D, YYYY [at] h:mm A',
  //   sameElse : 'MMMM D, YYYY [at] h:mm A'
  // });

  return moment.unix(unix).fromNow();
}

/////////////////////////////
// isDateBeforeOrAfterFromNow()
/////////////////////////////
export const isDayBeforeOrAfterFromNow = (paramDateTime, paramRawFormat) => {
  
  let dateTime = paramDateTime;

  if (paramRawFormat === 'unix') {
    dateTime = moment.unix(dateTime);
  }
  
  return {
    isBefore: moment().isBefore(dateTime, 'day'),
    isAfter: moment().isAfter(dateTime, 'day')
  };
}

/////////////////////////////
// isEmpty()
/////////////////////////////
export const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}

/////////////////////////////
// getImageCache()
/////////////////////////////
export const getImageCache = (profilePicturePath) => new Promise((resolve, reject) => {
  const profilePicture = new Image();

  profilePicture.onload = () => resolve(profilePicturePath);
  profilePicture.onerror = () => reject();

  profilePicture.src = profilePicturePath;
});

/////////////////////////////
// getEventReminder()
/////////////////////////////
export const getEventReminder = (reminder) => {
  const minuteEquivalentToHour = 60;

  if (reminder >= minuteEquivalentToHour) {
    const reminderInHour = reminder/minuteEquivalentToHour;

    if (reminderInHour !== 1) {
      return `${reminderInHour} hours before`
    } else {
      return `${reminderInHour} hour before`
    }
  } else if (reminder == 1) {
    return 'At Event'
  } else if (reminder == 0) {
    return 'No Reminder'
  } else {
    return `${reminder} minutes before`
  }
}

export const getEventParticipantInfo = (userId, eventSender, eventReceiver, userInfo) => {
  const calendarSenUserId		= eventSender.user.userId;

  if (userInfo === 'userName') {
    const calendarSenUsername	= eventSender.user.userName;
    const calendarRecUsername	= eventReceiver.user.userName;
  
    if (userId !== calendarSenUserId) {
      return calendarSenUsername
    } else {
      return calendarRecUsername
    }
  } else if (userInfo === 'user') {
    if (userId !== calendarSenUserId) {
      return eventSender.user
    } else {
      return eventReceiver.user
    }
  }
}

export const getEventLocation = (eventPlace, street, city, state, zipCode) => {
  let eventLocation = [];

  eventPlace !== null && eventPlace !== '' && eventLocation.push(eventPlace);
  street 		 !== null && street 		!== '' && eventLocation.push(street);
  city 			 !== null && city 			!== '' &&	eventLocation.push(city);
  state 		 !== null && state 		  !== '' && eventLocation.push(state);
  zipCode 	 !== null && zipCode 	  !== '' && eventLocation.push(zipCode);

  return eventLocation.join(', ')
}

export const urlParamToJSON = (url) => {
  return JSON.parse('{"' + url.replace(/&/g, '","').replace(/=/g,'":"') + '"}', (key, value) => { return key===""?value:decodeURIComponent(value) })
}

export const formatPhoneNumber = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value; 
	
  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length; 

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue; 

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
  }
}

export const getRandomNumber = (min, max) => {
  const randomNumber = Math.random() * (max - min) + min;

  return randomNumber;
}
