
import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../utils/colors';


const useStyles = makeStyles(theme => ({

  root:{
    display         : 'flex',
    flexDirection   : 'row',
    justifyContent  : 'flex-start',
    backgroundColor : colors.WHITE,
    borderRadius    : 10,
  },

  permissionContainer:{
    display         : 'flex',
    flex            : 1,
    justifyContent  : 'flex-end',
  },

  box: {
    width: '100%',
    height: '850px',
  },

  textLabel: {
    backgroundColor : 'transparent',
    color           : 'black',
    fontSize        : '20px',
    fontWeight      : 'bold',
    textAlign       : 'left',
    padding         : '30px 30px 20px 30px',

  },

  personalInfo: {
    display         : 'flex',
    flexDirection   : 'column'
  },

  textInputBox:{
    display         : 'flex',
    padding         : '10px 30px 10px 30px',
    "@media only screen and (max-width: 768px)": {
      width         : '100%',
      flexDirection : 'column',
    }
  },

  textInput:{
    width           : '400px',
    height          : '50px',
    marginRight     : '20px',
   },

  inputLabel: {
    fontSize        : '16px',
    color           : '#484C53',
    paddingBottom   : '5px'
  },

  btnContainer: {
    display         : 'flex',
    justifyContent  : 'flex-end',
    margin          : '20px 40px 30px 30px',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#C0C0C0',
      color         : colors.WHITE
    }
  },

  profileInfoBtn: {
    width           : '130px',
    height          : '40px',
    fontSize        : '15px',
  },

  changePassBtn: {
    width           : '165px',
    height          : '40px',
    fontSize        : '15px',
    marginRight      : '10px'
  },

  cancelBtn: {
    color           : colors.TEXT_BLACK,
    fontSize        : '16px',
    display         : 'flex',
    alignSelf       : 'flex-end',
    cursor          : 'pointer',
  },

  emailInput:{
    width           : '820px',
    height          : '50px',
    paddingBottom   : '10px'
  },

  permissionBox:{
    backgroundColor : colors.PRIMARY,
    height          : 600,
    flex            : 'wrap',
    display         : 'flex',
    justifyContent  : 'flex-start',
    flexDirection   : 'column',
    padding         : 30,
    width           : 150,
    borderRadius    : 10,
  },

  permissionHeader:{
    color           : colors.WHITE,
    fontWeight      : 600,
    borderRadius    : 10,
    marginLeft      : 10
  },

  listBox:{
    width           : '100%',
    maxWidth        : 360,
    backgroundColor : colors.PRIMARY,
    display         : 'flex',
    justifyItems    : 'center',
    flexDirection   : 'column',
    overflow        : 'auto',
  },

  iconList:{
    fontSize        :'small',
    color           : colors.WHITE,
  },

  itemList:{
    color           : colors.WHITE,
    marginLeft      : 10,
  },

  tabs: {
    borderRight     : `1px solid ${theme.palette.divider}`,
    color           : colors.PRIMARY,
    paddingTop      : 40,
  },
  labelTabs:{
    fontWeight      : 600,
    fontSize        : '16px',
  },
  labelTabsSelected : {
    fontWeight      : 600,
    fontSize        : '16px',
    borderLeft      : `5px solid ${colors.PRIMARY}`,
  }

 }));

export default useStyles;