import React, { memo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import useStyles from "./style";

const Empty = (props) => {
	const classes = useStyles();
  const { title, description, image } = props;

	return (
		<Grid item xs={12}>
			<Box className={classes.container}>
				<img src={image} rel={title}/>
				<Typography color='textPrimary' variant='subtitle1' className='text-bold'>{title}</Typography>
				<Typography color='textPrimary' variant='subtitle2'>{description}</Typography>
			</Box>
		</Grid>
	)
}

export default memo(Empty);