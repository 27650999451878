const action = {

  SIGN_OUT  : "SIGN_OUT",

  signOutUser: () => ({
    type : action.SIGN_OUT
  }),

};

export default action;