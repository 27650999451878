import React               from 'react';
import useStyles           from './style';
import Modal               from '@material-ui/core/Modal';
import CloseIcon           from '@material-ui/icons/Close';
import { Box, Typography, IconButton } from '@material-ui/core';


const CModal = (props) => {
  console.log('Cmodal props: ', props);
  const classes = useStyles();

  let { title, isVisible, onClose } = props;

  return (
    <Modal
      open    = { isVisible }
      onClose = { () => onClose }>
        <Box className={classes.modal}>
          <Box>
            <Box className={classes.closeIconContainer}>
              <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIcon/>
              </IconButton>
            </Box>
            <Box className={classes.title}>{title}</Box>
          </Box>


          <Box className={classes.mainContent}>
            {props.children}
          </Box>
        </Box>
    </Modal>
  )
};
export default CModal;