import { combineReducers } from 'redux';
import { sessionReducer, sessionService } from 'redux-react-session';
import action from './action';
import Search from './search/reducer';
import User from './user/reducer';

const appReducers = combineReducers({
  Search,
  User,
  Session: sessionReducer
});

const rootReducer = (state, act) => {
  console.log('rootReducer act: ', act);


  switch(act.type) {


    case action.SIGN_OUT:
      console.log("redux/reducers.js:RESET_APP");
      const initialState = {};
      sessionService.deleteSession();
      sessionService.deleteUser();
      return appReducers(initialState, act);

    default:
      return appReducers(state, act);
  }
};

export default rootReducer;