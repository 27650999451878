import React       from 'react';
import { useSelector } from 'react-redux';
import Restricted     from '../components/restricted';
import { PERMISSION } from '../utils/constants';


// gets user permissions from redux
const withPermission = (requiredPermissions) => (Component, isMainPage = false) => (props) => {
  // use this to test permissions
  // let userPermissions = [ PERMISSION.ROLES.DELETE, PERMISSION.ROLES.UPDATE, PERMISSION.ROLES.CREATE ]; //use this to test

  let userPermissions = useSelector((state) => state.User?.permissions);
  console.log('userPermissions: ', userPermissions);

  if(requiredPermissions.length > 1) { // if the user has more than one permissions
    let hasRole = false;
    userPermissions.forEach(userPerm => {
      requiredPermissions.forEach(p => {
        if(userPerm === p) {
          hasRole = true;
        }
      })
    });
    return (hasRole) ? <Component {...props} /> : <Restricted/>

  } else if (userPermissions.includes(requiredPermissions[0])) {
    return <Component {...props} />;
  } else {
    return (isMainPage) ? <Restricted/> : null;
  }
};



//Home Permissions
export const withHomeViewPermission = withPermission([PERMISSION.HOME.VIEW]);

export const withRoleDeletePermission = withPermission([PERMISSION.ROLES.DELETE]);
export const withRoleAddPermission    = withPermission([PERMISSION.ROLES.CREATE]);
export const withRoleEditPermission   = withPermission([PERMISSION.ROLES.UPDATE]);


///////////////////////////
// mapStateToProps()
///////////////////////////
// const mapStateToProps = (state) => {
//   return {
//     user: state.User
//   };
// };

export default withPermission;

// This is important
// export default (WrappedComponent) =>
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withMyHoc(WrappedComponent));
//
// export default (WrappedComponent) =>
//   connect(
//     mapStateToProps,
//     null
//   )(withPermission(WrappedComponent));

// export default (Component) => connect(mapStateToProps, null)(withPermission(Component));


