import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../utils/colors';

const useStyles = makeStyles(({

  card: {
    flex                : 1,
    backgroundColor     :colors.WHITE,
    maxWidth            : 500,
    height              : 270,
    borderRadius        : 5
  },

}));

export default useStyles;