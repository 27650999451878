const action = {

  ADMIN_SIGNIN: 'ADMIN_SIGNIN',UPDATE_PERSONAL_INFO: 'UPDATE_PERSONAL_INFO',
  GET_USER_ADMIN_PERMISSION: 'GET_USER_ADMIN_PERMISSION',SET_USER_ADMIN_PERMISSION: 'SET_USER_ADMIN_PERMISSION',

  signInAdmin: userToken => ({
    type    : action.ADMIN_SIGNIN,
    payload : userToken,
  }),
  updatePersonalInfo: (field,value) => ({
    type    : action.UPDATE_PERSONAL_INFO,
    payload : {field,value},
  }),
  getAdminPermission: (payload) =>({
    type    : action.GET_USER_ADMIN_PERMISSION,
    payload : payload
  }),
  setAdminPermission: (payload)=>({
    type    : action.SET_USER_ADMIN_PERMISSION,
    payload : payload
  })

};

export default action;
