import React, { useState }          from 'react';
import { Button, CircularProgress } from '@material-ui/core/'
import useStyles                    from './style';

// const PrimaryButton = ({ children, text, isLoading, onClick, className, type, ...rest }) => {
const PrimaryButton = (props) => {

  let {
    children,
    text,
    isLoading,
    onClick,
    className = [],
    type,
    ...rest
  } = props;


  const classes = useStyles();

  return (
    <Button
      type      = { type && type }
      className = { [classes.button, className] }
      onClick   = { onClick }
      disabled  = { isLoading }
      size      = 'medium'
      variant   = 'contained'
      color     = 'primary'
      {...rest}>
        {
          isLoading ? <CircularProgress size={20} color='secondary'/> : <>{text} {children}</>
        }
    </Button>
  )
};
export default PrimaryButton;