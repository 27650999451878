import axios          from 'axios';
import axiosInstance  from './axios'
import api            from './api';
// import {}                     from '../utils/constants';

let cancelToken;
let source;

export const multipleRequest = (request) => axios.all(request);


export const searchRequest = {
}

export const userAdminRequest = {
  getAllRoles: () => axiosInstance.get(
    api.GET_ALL_ROLES
  ),

  getAllPermissions: () => axiosInstance.get(
    api.GET_ALL_PERMISSIONS
  ),

  getRole: (params) => axiosInstance.get(
    api.GET_ROLE,
    {
      params
    }
  ),

  getRoleAdmins: (params) => axiosInstance.get(
    api.GET_ROLE_ADMINS,
    {
      params
    }
  ),

  createRole: (data) => axiosInstance.post(
    api.CREATE_ROLE,
    data
  ),

  updateRole: (data) => axiosInstance.post(
    api.UPDATE_ROLE,
    data
  ),

  createAdmin: (data) => axiosInstance.post(
    api.CREATE_NEW_ADMIN,
    data
  ),
  
  deleteRole: (data) => axiosInstance.post(
    api.DELETE_ROLE,
    data
  ),
  
  updatePersonalInformation: (data) => axiosInstance.post(
    api.UPDATE_PERSONAL_INFORMATION,
    data
  ),
  
  changeEmail: (data) => axiosInstance.post(
    api.CHANGE_EMAIL,
    data
  ),

  changePassword: (data) => axiosInstance.post(
    api.CHANGE_PASSWORD,
    data
  ),

  getUserAdminPermission: (params) => axiosInstance.get(
    api.GET_USER_ADMIN_PERMISSION,
    {
      params
    }
  ),

}

export const userRequest = {
    getAllUsers: (params) => axiosInstance.get(
      api.GET_ALL_USER,
      {
        params
      }
    ),

    getAdminUsers: (params) => axiosInstance.get(
      api.GET_ALL_ADMIN_USER,
      {
        params
      }
    ),

    getTotalUsers : () => axiosInstance.get(
      api.GET_TOTAL_USER
    ),

    getUsersLocation: () => axiosInstance.get(
      api.GET_USER_LOCATION,
    ),
    
    signInAdmin: (data) => axiosInstance.post(
      api.ADMIN_SIGNIN,
      data,
    )

}
