import Swal from 'sweetalert2';
  import withReactContent from 'sweetalert2-react-content';


export const showSuccessAlert = (text) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: 'success',
    // title: 'Success',
    text,
    // heightAuto: false,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });
};

export const showWarningAlert = (text) => {
  const MySwal = withReactContent(Swal)
  MySwal.fire({
    icon: 'warning',
    title: 'Warning',
    text,
    // heightAuto: false,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });
};

export const showFailedAlert = (text) => {
  const MySwal = withReactContent(Swal)
  MySwal.fire({
    icon: 'error',
    // title: null,
    title: 'Error',
    text: text || 'An error has occurred, Please try again',
    heightAuto: false,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
  });
};

export const showErrorResetPassword = (text) => {
  const MySwal = withReactContent(Swal)
  MySwal.fire({
    icon: 'error',
    title: 'Error',
    text,
    heightAuto: false,
    // toast: true,
    position: 'center',
    showConfirmButton: true,

  });
};






export const openSwalAlert = (...props) => {
  const MySwal = withReactContent(Swal)
  return MySwal.fire(...props);
};