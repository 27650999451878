import { makeStyles } from '@material-ui/core/styles';
import colors         from '../../../utils/colors';
import { createTheme, ThemeProvider, Typography } from "@material-ui/core";
const useStyles = makeStyles(({

  card: {
    textAlign           : 'left',
    width               : '92%',
    borderRadius        : 5,
    paddingLeft         : 12,
    paddingRight        : 12,
    paddingTop          : 5,
    justifySelf         : 'center',
  },

  content: {
    display             : 'flex',
    flexDirection       : 'column',
    paddingBottom       : '4px !important'
  },

  preLoaderText: {
    backgroundColor: colors.PRELOADER_SHADOW,
    height: 18,
    marginBottom: 3,
    marginTop: 3,
  },

  title: {
    font: 'normal normal bold 20px/27px Open Sans',
    color: '#9A9A9A',
  },

  subTitle: {
    font: 'normal normal bold 30px/41px Open Sans',
    color: '#212121'
  },

  grid: {
    ['list-style-type']: 'none',
    padding: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    "& li": {
      display: 'flex',
      flexDirection: 'column',
      gap: 7,
    }
  },

  listTitle: {
    font: 'normal normal 600 16px/22px Open Sans',
    color: '#9A9A9A',
  },

  listContent: {
    font: 'normal normal 600 16px/22px Open Sans',
    color: '#212121',
  },

  margin: {
    marginLeft: 'auto'
  },


  OrangeText: {
    color               : colors.PRIMARY,
    TextTransform       : 'capitalize',
    marginBottom        : 10,
    fontSize            : 20,
    fontWeight          : 'bold'

  },

  BlackText: {
    colors              : colors.BLACK,
    TextTransform       : 'capitalize',
    marginBottom        : 10,
    fontSize            : 20
  }

}));

export default useStyles;