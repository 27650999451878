import { makeStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors";

const useStyles = makeStyles((theme) => ({

  
  userCards: {
    display             : 'grid',
    gridTemplateColumns : '1fr 1fr 1fr 1fr',
    rowGap              : '26px',
    columnGap           : '15px',
    justifyContent      : 'space-between',
  
    ['@media (max-width:1200px)']: {
      gridTemplateColumns: '1fr 1fr',
    },
    ['@media (max-width:780px)']: {
      gridTemplateColumns: '1fr',
    },
  },

  card: {
    borderRadius        : 5,
    padding             : 20
  },

  container: {
    display             : 'flex',
    flexDirection       : 'column',
    gap                 : 20,
    padding             : '0px 20px 20px 100px'
  }

  // homeContainer: {
  //   padding: "1em",
  //   [theme.breakpoints.up("md")]: {
  //     padding: "4em",
  //     paddingLeft: "5em",
  //     paddingRight: "5em",
  //   },
  //   backgroundColor: colors.BACKGROUND_COLOR,
  //   position: 'absolute',
  //   marginTop: '120px',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  //   left: 0,
  //   right: 0,
  // },

  // messageContainer: {
  //   background: 'linear-gradient(#FDC830, #F37335)',
  //   borderRadius: '10px',
  //   border: 'none',
  // },

  // linkToMessages: {
  //   textAlign: 'end',
  //   fontSize: '12px',
  //   position: 'absolute',
  //   bottom: 0,
  //   right: 0,
  //   marginRight: '1em',
  //   '& a': {
  //     color: colors.WHITE,
  //     borderBottom: 'solid 1px',
  //     cursor: 'pointer',
  //     fontSize: 12
  //   }
  // },

  // cardContentMessage: {
  //   position: 'relative',
  //   minHeight: '500px',
  //   [theme.breakpoints.down('xs')]: {
  //     padding: 0
  //   },
  //   '& > button' : {
  //     textDecoration : 'none! important'
  //   }
  // },

  // cardContentMessageWrapper: {
  //   margin: '1em'
  // },

  // cardMessageHeader: {
  //   color: colors.WHITE,
  //   marginLeft: '1em'
  // },
  
}));

export default useStyles;
